import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";

interface Rating {
  rating: number;
  count: number;
}

interface RatingChartProps {
  data: {
    review_count: number;
    star_counts: {
      one_star: number;
      two_star: number;
      three_star: number;
      four_star: number;
      five_star: number;
    };
  };
}

const RatingChart: React.FC<RatingChartProps> = ({ data }) => {
  const totalReviews = data.review_count;
 
  const ratings: Rating[] = [
    { rating: 5, count: data.star_counts.five_star },
    { rating: 4, count: data.star_counts.four_star },
    { rating: 3, count: data.star_counts.three_star },
    { rating: 2, count: data.star_counts.two_star },
    { rating: 1, count: data.star_counts.one_star },
  ];

  return (
    <Box sx={{ width: "100%", margin: "0 auto" }}>
      {ratings.map((item) => {
        const percentage = totalReviews > 0 ? (item.count / totalReviews) * 100 : 0;
        return (
          <Box key={item.rating} display="flex" alignItems="center">
            <Typography
              variant="body1"
              style={{ minWidth: 20, fontWeight: "400" }}
            >
              {item.rating}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={percentage}
              style={{
                flexGrow: 1,
                margin: "0 5px",
                backgroundColor: "#A4A1AA33",
                height: "8px",
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default RatingChart;
