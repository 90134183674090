import { useState } from "react";
import Carousel from "react-multi-carousel";
import { Box, Container, useMediaQuery } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import { useTranslation } from "react-i18next";
import { Attachment } from "../pages/productDetails";

interface SlickSliderProps {
    sliderItems: Attachment[];
    height?: string;
}

const ReviewSlider = ({ sliderItems, height }: SlickSliderProps) => {
    const [dotActive, setDocActive] = useState(0);
    const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
    const { i18n } = useTranslation();

    const dir = i18n.dir();

    const CustomDot = ({ onClick, active }: any) => {
        return (
            <li
                style={{
                    display: 'inline-block',
                    margin: '0 3px',
                    cursor: 'pointer',
                    marginBottom: '10px'
                }}
            >
                <div
                    onClick={onClick}
                    style={{
                        background: active ? "#000" : "#A4A1AA33",
                        borderRadius: "6px",
                        width: "12px",
                        height: "12px",
                        display: "inline-block",
                        border: "none",
                        WebkitAppearance: "none",
                        cursor: "pointer",
                    }}
                />
            </li>
        );
    };

    return (
        <Container
            maxWidth="lg"
            sx={{
                padding: isMobile ? 0 : "0 0px",
                position: "relative",
                margin: { xs: "0", sm: "0 auto" },
                width: { xs: "100%", sm: "90%" },

                "& .react-multi-carousel-list.container": {
                    paddingX: '0px'
                },

            }}
        >
            <Carousel
                beforeChange={() => {
                }}
                customDot={<CustomDot />}

                additionalTransfrom={0}
                arrows={false}
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024,

                        },
                        items: 1
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 1
                    }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={dir === 'rtl'}
                shouldResetAutoplay
                showDots
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                {sliderItems &&
                    sliderItems.map((item: Attachment, index: number) => (
                        <Box
                            key={item.id}
                            sx={{
                                position: "relative",
                                display: "flex !important",
                                justifyContent: "center !important",
                                objectFit: "contain",
                                height: height ? height : '100%'
                            }}
                        >
                            {item.type === 'video' ? (
                                <video
                                    src={item.url}
                                    controls
                                    style={{
                                        width: "100%",
                                        minHeight: "190px",
                                        objectFit: "cover",
                                    }}
                                />
                            ) : (
                                <img
                                    draggable="false"
                                    src={item.url}
                                    alt={item.url}
                                    style={{
                                        width: "100%",
                                        minHeight: "190px",
                                        objectFit: "cover",
                                    }}
                                />
                            )}
                        </Box>
                    ))}
            </Carousel>
        </Container>
    );
};

export default ReviewSlider;
