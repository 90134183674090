import React, { useEffect, useState } from 'react';
import {
    Card, CardContent, CardMedia, Typography, Box, Grid, Container, Pagination,
    PaginationItem
} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Link, useNavigate } from 'react-router-dom';
import { CurrentLanguagePath } from '../../utils/helper';
import { blogService } from '../../api/services/blogService';
import HtmlParser from "react-html-parser";
import useAuthStore from '../../zustand/store/user';

interface BlogCardProps {
    id: string;
    title: string;
    description: string;
    date: string;
    image: string;
    slug: string;
}

const BlogCard: React.FC<BlogCardProps> = ({
    id,
    title,
    description,
    date,
    image,
    slug
}) => {
    const navigate = useNavigate();

    return (
        <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ boxShadow: "none", textDecoration: "none" }} component={Link} to={CurrentLanguagePath(`blog/${slug}`)}>
                <CardMedia
                    component="img"
                    height="250"
                    loading="lazy"
                    image={image}
                    alt={title}
                    sx={{
                        objectFit: 'cover',
                        cursor: 'pointer',
                        borderRadius: '10px',
                    }}
                />
                <CardContent sx={{ px: "15px", alignSelf: "flex-start", padding: "10px 0px !important" }}>
                    <Typography
                        sx={{
                            color: "#292b2c",
                            fontSize: "1rem",
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'clip',
                            WebkitLineClamp: 2,
                            lineHeight: '1.5rem',
                            minHeight: '3rem'
                        }}
                        variant="h6"
                        gutterBottom
                    >
                        {HtmlParser(title)}
                    </Typography>

                    <Typography sx={{
                        color: "#687188",
                        fontSize: "16px",
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'clip',
                        WebkitLineClamp: 4,
                        lineHeight: '1.5rem',
                        minHeight: '6rem'
                    }}>
                        {description}
                    </Typography>
                    <Box display="flex" alignItems="center" sx={{ my: 2 }}>
                        <CalendarMonthIcon sx={{ mr: 0.5, color: "#687188" }} fontSize="small" />
                        <Typography variant="body2" sx={{ color: "#687188" }}>
                            {HtmlParser(date)}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    );
};

const Blog = () => {
    const [blogs, setBlogs] = useState<any[]>([]);
    const [page, setPage] = useState<number>(1);
    const [totalPage, setTotalPage] = useState<number>(1);
    const [totalBlogs, setTotalBlogs] = useState<number>(0);

    const getBlog = async (currentPage: Number = 1) => {
        try {
            let pageCount = currentPage ?? page;
            let obj = {
                page: pageCount,
            };
            console.log(obj)
            const response = await blogService.getBlog(obj);
            if (response && response.status === 200) {
                const { blogs, total, current_page, last_page } = response.data;
                setBlogs(blogs);
                setTotalPage(last_page);
                setTotalBlogs(total);
            } else {
                console.log(response.data, "error");
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getBlog(page);
    }, [page]);

    const handleChangePage = (
        event: any,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    return (
        <div style={{ minHeight: "100vh" }}>
            <Box sx={{ px: "10px", py: "20px" }}>
                <Container>
                    <Grid container spacing={3}>
                        {blogs && blogs.length > 0 ? (
                            blogs.map((post: any) => (
                                <BlogCard key={post.id} {...post} />
                            ))
                        ) : (
                            !useAuthStore.getState().isLoading && blogs && blogs.length === 0 && (
                                <Typography>No blogs found</Typography>
                            )
                        )}
                    </Grid>
                    {totalPage > 1 && (
                        <Box display="flex" justifyContent="center" mt={4}>
                            <Pagination
                                count={totalPage}
                                page={page}
                                onChange={handleChangePage}
                                shape='rounded'
                                renderItem={(item) => (
                                    <PaginationItem
                                        {...item}
                                        className={`custom-button-class ${item.type === 'previous' ? 'rtl-transform' : item.type === 'next' ? 'rtl-transform' : ''}`}
                                    />
                                )}
                            />
                        </Box>
                    )}
                </Container>
            </Box>
        </div>
    );
};

export default Blog;
