const pushToDataLayer = (eventName: string, eventData = {}) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: eventName,
      ...eventData,
    });
  } else {
    console.warn("GTM dataLayer is not available");
  }
};
const createEvent = (eventName: string, data: Record<string, any>) => {
  pushToDataLayer(eventName, data);
};

const AddToCart = (productDetails: any) => {
  createEvent("addToCart", productDetails);
};

const RemoveFromCart = (productDetails: any) => {
  createEvent("removeFromCart", productDetails);
};

const AddToWishlist = (productDetails: any) => {
  createEvent("addToWishlist", productDetails);
};

const ViewCart = (productDetails: any) => {
  createEvent("viewCart", productDetails);
};

const AddShippingInfo = (shipping: any) => {
  createEvent("addShippingInfo", shipping);
};

const AddCheckout = (checkoutData: any) => {
  createEvent("checkout", checkoutData);
};

const SelectContent = (content: any) => {
  createEvent("selectContent", content);
};

const ViewItem = (item: any) => {
  createEvent("viewItem", item);
};

const ShareItem = (item: any) => {
  createEvent("shareItem", item);
};

const RefundItems = (refund: any) => {
  createEvent("refund", refund);
};

const SignUp = (method: string) => {
  createEvent("sign_up", { method });
};

const LogIn = (method: string) => {
  createEvent("login", { method });
};

const Purchase = (data: any) => {
  createEvent("purchase", data);
};

const ViewBanner = (banner: any) => {
  createEvent("view_banner", banner);
};

const GenerateLead = (data: any) => {
  createEvent("generate_lead", data);
};

const ViewItemList = (ListItems: any) => {
  createEvent("view_item_list", ListItems);
};

const JoinGroup = (group_id: string) => {
  createEvent("join_group", { group_id });
};

export {
  AddToCart,
  RemoveFromCart,
  AddToWishlist,
  ViewCart,
  AddShippingInfo,
  AddCheckout,
  SelectContent,
  ViewItem,
  ShareItem,
  RefundItems,
  SignUp,
  LogIn,
  Purchase,
  ViewBanner,
  GenerateLead,
  ViewItemList,
  JoinGroup,
};
