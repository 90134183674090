import React from "react";

const Loader: React.FC = () => {
  return (
    <div className="loader-overlay">
      <div className="loader-container">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        {/* Optionally, you can add a loading text */}
        {/* <p className="loading-text">Loading...</p> */}
      </div>
    </div>
  );
};

export default Loader;
