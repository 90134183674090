import React from "react";
import {
  Box,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { RefundItem } from "../types/checkout";
import { useTranslation } from "react-i18next";

interface RefundItemDialogProps {
  open: boolean;
  onClose: () => void;
  refundProducts: RefundItem[];
}

const RefundItemDialog: React.FC<RefundItemDialogProps> = ({
  open,
  onClose,
  refundProducts,
}) => {
  const { i18n, t } = useTranslation();
  const direction = i18n.dir();
  const rtl = direction === "rtl" ? "left" : "right";
  const margin = direction === "rtl" ? "marginRight" : "marginLeft";
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {t("order.refund_product_details")}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            [rtl]: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ padding: "10px 10px" }}>
          {refundProducts.map((product, index) => (
            <Grid
              key={index}
              container
              spacing={2}
              alignItems="center"
              sx={{
                flexDirection: { xs: "row", sm: "row" },
                display: "flex",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  my: 2,
                }}
              >
                <img
                  src={product.product_image_url}
                  alt={product.product_name}
                  style={{
                    maxWidth: "100px",
                    minHeight: "100px",
                    borderRadius: "4px",
                    objectFit: "cover",
                  }}
                />
                <Grid
                  item
                  xs={8}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    [margin]: "8px",
                    alignItems: { xs: "flex-start", sm: "flex-start" },
                    fontSize: { xs: "15px", sm: "10px" },
                  }}
                >
                  <Typography variant="body1" fontWeight="500" fontSize="14px">
                    {product.product_name}
                  </Typography>
                  <Typography fontSize="12px" mt={1}>
                    Price: {product.price}
                  </Typography>
                  <Typography fontSize="12px" mt={1}>
                    Color: {product.color_name}
                  </Typography>
                  <Typography fontSize="12px" mt={1}>
                    Size: {product.size}
                  </Typography>
                  <Typography fontSize="12px" mt={1}>
                    Quantity: {product.quantity}
                  </Typography>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RefundItemDialog;
