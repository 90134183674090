import axiosInstance from "../apiClient";
import API from "../endpoints";

export const blogService = {
  getBlog: (data: any) => {
    return axiosInstance.get(API.BLOGS, {
      params: { page: data.page },
    });
  },
  getBlogDetails: (id: string) => {
    return axiosInstance.get(`${API.BLOGS}/${id}`);
  },
};
