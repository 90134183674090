import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { CurrentLanguagePath } from "../utils/helper";
import { productService } from "../api/services/productService";

interface SearchBarProps {
  onClose: () => void;
  onSearch?: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const suggestionsRef = useRef<HTMLDivElement | null>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleSearch = (text?: string) => {
    let txt = text ? text.trim() : searchText.trim();
    if (txt) {
      navigate(CurrentLanguagePath(`products/search/?q=${txt}`), {
        replace: true,
      });
      onClose && onClose();
    }
  };

  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      if (searchText) {
        setLoading(true);
        productService
          .search(
            { keywords: searchText, page: currentPage, per_page: 10 },
            { showLoader: false }
          )
          .then((res) => {
            setLoading(false);
            if (currentPage === 1) {
              setSuggestions(res.data?.data || []);
            } else {
              setSuggestions(
                (prev) => [...prev, ...(res.data?.data || [])] as any
              );
            }

            setTotalPages(res.data?.last_page || 0);
            setCurrentPage(res.data?.current_page || 1);
          });
      } else {
        setSuggestions([]);
      }
    }, 300); // Debounce delay

    setDebounceTimeout(timeout);

    return () => {
      clearTimeout(timeout); // Cleanup on unmount
    };
  }, [searchText, currentPage]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const node = e.currentTarget;
    const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;

    if (bottom && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <Container>
      <TextField
        autoFocus
        fullWidth
        variant="standard"
        placeholder="Search..."
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
          setCurrentPage(1);
        }}
        InputProps={{
          endAdornment: (
            <IconButton
              edge="end"
              style={{ color: "inherit" }}
              onClick={() => handleSearch()}
            >
              <Search />
            </IconButton>
          ),
          sx: {
            color: "white",
          },
        }}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            onClose();
          } else if (e.key === "Enter") {
            handleSearch();
          }
        }}
        sx={{
          borderBottom: "1px solid white",
          fontSize: "16px",
          "& .css-uhlitr-MuiInputBase-root-MuiInput-root": {
            borderBottom: "1px solid white",
          },
          "& .MuiInputBase-input": {
            color: "white",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderBottom: "1px solid white",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderBottom: "1px solid white",
          },
          color: "white",
        }}
      />
      <Box bgcolor="#fff" borderRadius={1}>
        {searchText && suggestions.length > 0 && (
          <Box
            mt={2}
            bgcolor="#fff"
            borderRadius={1}
            overflow="auto"
            maxHeight={500}
            onScroll={handleScroll}
          >
            {suggestions?.map((product: any) => (
              <Box
                key={product.id}
                display="flex"
                alignItems="center"
                p={1}
                onClick={() => {
                  navigate(
                    CurrentLanguagePath(`product-details/${product.slug}`)
                  );
                  onClose && onClose();
                }}
                sx={{
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.2)" },
                }}
              >
                <img
                  src={product.image}
                  alt={product.title}
                  style={{
                    width: 90,
                    height: 100,
                    marginRight: 8,
                    marginLeft: 8,
                  }}
                />
                <Typography variant="body1" color="#000">
                  {product.title}
                </Typography>
              </Box>
            ))}
          </Box>
        )}

        {loading && (
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress size={24} sx={{ color: "#000" }} />
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default SearchBar;
