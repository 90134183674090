import { checkboxClasses, createTheme } from "@mui/material";

export const themeRTL = createTheme({
  direction: "ltr",
  palette: {
    primary: {
      light: "#A4A1AA",
      main: "#000000",
    },
  },

  typography: {
    fontSize: 14,
    fontWeightLight: "400",
    allVariants: {
      color: "#000000",
    },
    fontFamily: "DINNEXT",
  },

  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: "#000000",
          fontSize: 14,
          borderRadius: "4px",
          fontFamily: "DINNEXT",
        },
      },
    },
    // MuiSvgIcon: {
    //   styleOverrides: {
    //     root: {
    //       "body[dir=rtl] &": {
    //         transform: "scaleX(-1)",
    //       },
    //     },
    //   },
    // },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "400 !important",
          lineHeight: "23.12px",
          textAlign: "left",
          fontFamily: "DINNEXT",

          "&:hover": {
            boxShadow: "none",
          },
          "&.Mui-disabled": {
            opacity: 0.5,
            color: "white",
          },
        },
        contained: {
          background: "#131118",
          color: "#fff",
        },
        outlined: {
          color: "#131118",
          border: "solid 1px #131118",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          [`&.${checkboxClasses.checked}`]: {
            color: "#131118",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: "DINNEXT",
        },
      },
    },
  },
});

export const themeLTR = createTheme({
  direction: "ltr",
  palette: {
    primary: {
      light: "#A4A1AA",
      main: "#000000",
    },
  },

  typography: {
    fontSize: 14,
    fontWeightLight: "400",
    allVariants: {
      color: "#000000",
    },
    fontFamily: "Jost",
  },

  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: "#000000",
          fontSize: 14,
          borderRadius: "4px",
        },
      },
    },
    // MuiSvgIcon: {
    //   styleOverrides: {
    //     root: {
    //       "body[dir=rtl] &": {
    //         transform: "scaleX(-1)",
    //       },
    //     },
    //   },
    // },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "400 !important",
          lineHeight: "23.12px",
          textAlign: "left",
          "&:hover": {
            boxShadow: "none",
          },
          "&.Mui-disabled": {
            opacity: 0.5,
            color: "white",
          },
        },
        contained: {
          background: "#131118",
          color: "#fff",
        },
        outlined: {
          color: "#131118",
          border: "solid 1px #131118",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          [`&.${checkboxClasses.checked}`]: {
            color: "#131118",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: "Jost",
        },
      },
    },
  },
});
