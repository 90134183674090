import React, { useEffect, useState } from "react";
import {
  Box,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Popover,
  Button,
  useMediaQuery,
  Container,
  Divider,
  ListSubheader,
  Grid,
} from "@mui/material";
import { AppBar, CenterBox, MenuBox } from "./styles";
import { Link } from "react-router-dom";
import {
  ArrowBack,
  Close,
  KeyboardArrowRight,
  Menu as MenuIcon,
  Search as SearchIcon,
  WestOutlined,
} from "@mui/icons-material";
import ShoppingBagDrawer from "../components/Drawer";
import { useLocation, useNavigate } from "react-router-dom";
import imagepath from "../constant/imagepath";
import { ReactComponent as Like } from "../../src/assets/svgIcons/like-heart-outline-symbol.svg";
import { ReactComponent as ProfileIcon } from "../../src/assets/svgIcons/profile.svg";
import { ReactComponent as CartIcon } from "../../src/assets/svgIcons/shopping-bag.svg";
import "./header.css";
import { StyledBadge, StyledTypography } from "../utils/theme/styles/Common";
import "./header.css";
import Navbar from "../components/Navbar";
import COLORS from "../constant/colors";
import i18n from "../utils/i18n";
import { themeLTR, themeRTL } from "../utils/theme/theme";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import SearchBar from "../components/SearchBar";
import { userService } from "../api/services/userService";
import ShowToast from "../utils/ShowToast";
import useAuthStore from "../zustand/store/user";
import { homeService } from "../api/services/homeService";
import { Category } from "../utils/type";
import { useCartStore } from "../zustand/store/cartData";
import { CurrentLanguagePath } from "../utils/helper";
import { useLanguageStore } from "../zustand/store/language";
import { useSettingStore } from "../zustand/store/useSettingStore";
import { SelectContent } from "../tagManager/gtag";
const theme = i18n.dir() === "ltr" ? themeLTR : themeRTL;
const useStyles = {
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  listItemImage: {
    marginRight: theme.spacing(2),
    width: 40,
    height: 40,
  },
  listItem: {
    WebkitTapHighlightColor: "transparent",
    alignItems: "center",
    // backgroundColor: "#f0f0f0",
    backgroundColor: "#00000040",
    backgroundPosition: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // display: "flex",
    fontSize: "20px",
    fontWeight: "700",
    height: "25vw",
    justifyContent: "space-between",
    maxHeight: "114px",
    minHeight: "94px",
    padding: "0 15px",
    position: "relative",
    textTransform: "uppercase",
    marginBottom: "10px",
  },
  listItemText: {
    // flex: "1",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
    fontSize: "20px",
    color: "#ffffff",
  },
  listItemIcon: {
    display: "flex",
    alignItems: "center",
    marginRight: "100px",
  },
  arrowIcon: {},
};

export default function Header() {
  const classes = useStyles;
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { pathname, search: query } = useLocation();
  const dir = i18n.dir();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [secondaryDrawerOpen, setSecondaryDrawerOpen] = React.useState(false);
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("md")
  );
  const { setLanguage, language } = useLanguageStore();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollDirection, setScrollDirection] = useState("up");
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const threshold = window.innerHeight * 0.2;
  const userInfo = useAuthStore((state: any) => state.userInfo);
  const isLoggedIn = useAuthStore((state: any) => state.isLoggedIn);
  const { globalOffers, getSetting } = useSettingStore();

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? "simple-popover" : undefined;
  const [search, setSearch] = useState(false);
  const { cartData, getCartItems } = useCartStore();
  const [menuItems, setMenuItems] = useState<Category[]>([]);
  const [currentCategory, setCurrentCategory] = useState<Category | null>(null);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);
  useEffect(() => {
    getSetting();
  }, [getSetting, language]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (Math.abs(position - lastScrollTop) >= threshold) {
      setScrollDirection(position > lastScrollTop ? "down" : "up");
      setLastScrollTop(position);
    }
    setScrollPosition(position);
  };

  // const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem("i18nextLng", language);
    const segments = pathname.split("/").filter(Boolean);
    const supportedLanguages = ["en", "ar"];
    if (supportedLanguages.includes(language)) {
      segments[0] = language;
    } else {
      segments.unshift(language);
    }

    const newPath = `/${segments.join("/")}${query || ""}`;
    setLanguage(language);
    navigate(newPath, { replace: true });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isSmallScreen) {
      if (isLoggedIn) {
        navigate(CurrentLanguagePath("account"));
      } else {
        navigate(CurrentLanguagePath("login"));
      }
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRegister = () => {
    setAnchorEl(null);
    navigate(CurrentLanguagePath("signup"));
  };

  const handleLogIn = () => {
    setAnchorEl(null);
    navigate(CurrentLanguagePath("login"));
  };

  const toggleShoppingBagDrawer = () => {
    setOpen(!open);
  };

  const handleSearchClose = (): void => {
    setSearch(false);
  };

  const logOut = async () => {
    try {
      const response: any = await userService.logout();
      if (response && response.status === 200) {
        ShowToast(response.message, "success");
        ["userData", "token", "couponShown"].forEach((k) =>
          localStorage.removeItem(k)
        );
        useAuthStore.getState().logout();
        setAnchorEl(null);
        window.location.href = "/";
      } else {
        console.log("error message:-", response.data);
      }
    } catch (error: any) {
      ShowToast(error.message, "error", "bottom-left");
      console.log(error);
    }
  };
  const handleMyAccount = () => {
    navigate(CurrentLanguagePath("account"));
    setAnchorEl(null);
  };
  const handleWishlist = () => {
    navigate(CurrentLanguagePath("wishlist"));
    setAnchorEl(null);
  };

  const renderProfileMenu = () => (
    <Popover
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "0px !important",
        },
      }}
    >
      <Box
        p={isLoggedIn ? "12px" : "16px"}
        sx={{
          width: "259px",
          height: "119px",
        }}
      >
        {isLoggedIn ? (
          <Box>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6" fontWeight="500" fontSize={"20px"}>
                HELLO
                <br />
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "200px",
                    fontSize: "14px",
                    lineHeight: "1.5",
                  }}
                >
                  {userInfo?.first_name} !😍
                </div>
              </Typography>
              <img
                className="rtl-transform"
                onClick={logOut}
                src={imagepath.arrow}
                alt="Arrow icon for logout"
                style={{
                  width: "28px",
                  height: "30px",
                  alignSelf: "flex-end",
                  bottom: 40,
                }}
              />
            </div>
            <Box sx={{ display: "flex", mt: 1, marginBottom: "10px" }}>
              <Button
                fullWidth
                onClick={() => handleMyAccount()}
                variant="outlined"
                sx={{
                  borderRadius: 0,
                  width: "122px",
                  height: "29px",
                  textTransform: "capitalize",
                  border: "1px solid #131118",
                }}
              >
                My Account
              </Button>
              <Button
                fullWidth
                onClick={() => handleWishlist()}
                variant="contained"
                sx={{
                  borderRadius: 0,
                  width: "122px",
                  height: "29px",
                  textTransform: "capitalize",
                }}
              >
                My Wishlist
              </Button>
            </Box>
          </Box>
        ) : (
          <Box>
            <StyledTypography variant="h6">GUEST</StyledTypography>
            <StyledTypography variant="body1" ml={"2px"}>
              HELLO
            </StyledTypography>
            <Box
              sx={{
                display: "flex",
                mt: 1,
                marginBottom: "10px",
              }}
            >
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  borderRadius: 0,
                  width: "122px",
                  height: "29px",
                  textTransform: "capitalize",
                  border: "1px solid #131118",
                }}
                onClick={handleRegister}
              >
                Register
              </Button>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  borderRadius: 0,
                  width: "122px",
                  height: "29px",
                  textTransform: "capitalize",
                }}
                onClick={() => handleLogIn()}
              >
                Login
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Popover>
  );

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await homeService.getCategories();
        if (response.status === 200) {
          setMenuItems(response.data);
        } else {
          console.log("Error fetching categories:", response.data);
        }
      } catch (error) {
        console.log("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, [language, setLanguage, i18n.language]);

  const links = [
    { text: t("shop"), to: "/" },
    { text: t("faq"), to: "faq" },
    { text: t("returnPolicy"), to: "return-policy" },
    { text: t("aboutUs"), to: "about-us" },
  ];

  useEffect(() => {
    getCartItems();
  }, [getCartItems, language]);

  const renderDrawer = (
    <>
      <Drawer
        anchor={dir === "rtl" ? "right" : "left"}
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{ sx: { width: "100%", direction: dir } }}
      >
        <div
          style={{
            display: "flex",
            WebkitTapHighlightColor: "transparent",
            fontSize: "0px",
            height: "55px",
            position: "absolute",
            [dir === "rtl" ? "left" : "right"]: "0px",
            top: "0px",
            transform: "translateZ(0px)",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px -6px 30px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            className="rtl-transform"
            onClick={toggleDrawer(false)}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <WestOutlined
              fontSize="small"
              sx={{
                width: "43px",
                height: "21px",
                color: "#000",
              }}
            />
          </IconButton>
        </div>
        <Box
          sx={{
            width: "100%",
            background: COLORS.WHITE,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: "0 10px",
            mt: 2,
          }}
          role="presentation"
        >
          {isLoggedIn ? (
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  fontWeight="500"
                  fontSize={"20px"}
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "200px",
                  }}
                >
                  HELLO
                  <br />
                  {userInfo?.first_name} !😍
                </Typography>
              </Box>
              <Box sx={{ display: "flex", mt: 1, marginBottom: "10px" }}>
                <Button
                  fullWidth
                  onClick={() => {
                    setDrawerOpen(false);
                    navigate(CurrentLanguagePath("account"));
                  }}
                  variant="outlined"
                  sx={{
                    borderRadius: 0,
                    width: "122px",
                    height: "29px",
                    textTransform: "capitalize",
                    border: "1px solid #131118",
                    fontSize: "12px !important",
                  }}
                >
                  My Account
                </Button>
                <Button
                  fullWidth
                  onClick={() => {
                    setDrawerOpen(false);
                    navigate(CurrentLanguagePath("wishlist"));
                  }}
                  variant="contained"
                  sx={{
                    borderRadius: 0,
                    width: "122px",
                    height: "29px",
                    textTransform: "capitalize",
                    fontSize: "12px !important",
                  }}
                >
                  My Wishlist
                </Button>
                <Button
                  fullWidth
                  onClick={logOut}
                  variant="outlined"
                  sx={{
                    borderRadius: 0,
                    width: "122px",
                    height: "29px",
                    textTransform: "capitalize",
                    border: "1px solid #131118",
                    fontSize: "12px !important",
                  }}
                >
                  Logout
                </Button>
              </Box>
            </Box>
          ) : (
            <Box sx={{ direction: dir }}>
              <StyledTypography variant="h6">GUEST</StyledTypography>
              <StyledTypography variant="body1" ml={"2px"}>
                HELLO
              </StyledTypography>
              <Box
                sx={{
                  borderBottom: "1px solid #000",
                  display: "flex",
                  margin: "0 -10px",
                  padding: "10px",
                }}
              >
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{
                    borderRadius: 0,
                    width: "100%",
                    height: "29px",
                    textTransform: "capitalize",
                    border: "1px solid #131118",
                  }}
                  onClick={handleRegister}
                >
                  Register
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    borderRadius: 0,
                    width: "100%",
                    height: "29px",
                    textTransform: "capitalize",
                  }}
                  onClick={() => handleLogIn()}
                >
                  Login
                </Button>
              </Box>
            </Box>
          )}

          <Divider />
          <List
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "30px 0",
              textAlign: "center",
              whiteSpace: "nowrap",
            }}
          >
            {links.map(
              (
                { text, to }: { text: string; to: string },
                index: number,
                arr: any
              ) => (
                <Link
                  to={to}
                  style={{ textDecoration: "none", color: "#000" }}
                  key={index}
                >
                  <Typography
                    key={text}
                    noWrap
                    sx={{
                      minWidth: { xs: "20px", md: "85px" },
                      textDecoration: pathname === to ? "underline" : "none",
                      cursor: "pointer",
                      fontSize: "16px",
                      fontWeight: "400",
                      color: pathname === to ? "primary.main" : "inherit",
                    }}
                    onClick={() => {
                      setDrawerOpen(false);
                    }}
                  >
                    {text}
                  </Typography>
                </Link>
              )
            )}
          </List>
          <Divider />
          <List sx={{}}>
            {menuItems.map((item, i) => (
              <ListItem
                key={i}
                sx={{
                  ...classes.listItem,
                  backgroundImage: `url(${item?.image})`,
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  setSecondaryDrawerOpen(true);
                  setCurrentCategory(item);
                }}
              >
                <Typography
                  component={"a"}
                  href={CurrentLanguagePath(`products/${item?.slug}`)}
                  onClick={(event) => {
                    setDrawerOpen(false);
                    event.stopPropagation();
                    return true;
                  }}
                  style={classes.listItemText}
                >
                  {item.name}
                </Typography>
                <KeyboardArrowRight
                  className="rtl-transform"
                  sx={{
                    color: "#ffffff",
                    height: "40px",
                    width: "40px",
                    textShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Drawer
          sx={classes.drawer}
          variant="persistent"
          anchor={dir === "rtl" ? "right" : "left"}
          open={secondaryDrawerOpen}
          PaperProps={{ sx: { width: "100%" } }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              fontSize: "16px",
              height: "55px",
              justifyContent: "center",
              padding: "5px 35px",
              position: "relative",
              textAlign: "center",
              textTransform: "uppercase",
              width: "100%",
              background: "#fff",
            }}
          >
            <IconButton
              className="rtl-transform"
              sx={{
                alignItems: "center",
                display: "flex",
                height: "20px",
                justifyContent: "center",
                [dir === "rtl" ? "right" : "left"]: "10px",
                position: "absolute",
                top: "16px",
                width: "30px",
                color: "#000",
              }}
              onClick={() => {
                setSecondaryDrawerOpen(false);
              }}
            >
              <ArrowBack />
            </IconButton>
            {currentCategory?.name}
          </Box>
          <Box
            sx={{
              height: "calc(100% - 50px)",
              overflowY: "auto",
              backgroundColor: "#fff",
            }}
          >
            <List>
              {currentCategory?.types?.map((category, index) => (
                <React.Fragment key={index}>
                  <ListSubheader
                    sx={{
                      borderBottom: "1px solid #000",
                      display: "block",
                      fontSize: "15px",
                      fontWeight: "500",
                      lineHeight: "1",
                      padding: "12px 20px",
                      textTransform: "uppercase",
                      width: "100%",
                    }}
                  >
                    <Typography variant="h6">{category?.name}</Typography>
                  </ListSubheader>
                  {category.sub_category.map((item, idx, arr) => (
                    <>
                      <ListItem
                        component={"a"}
                        href={CurrentLanguagePath(
                          `products/${currentCategory?.slug}?sub=${item?.slug}`
                        )}
                        key={idx}
                        sx={{
                          WebkitTapHighlightColor: "transparent",
                          alignItems: "center",
                          borderBottom: "1px solid #e2e2e2",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "12px 30px 12px 17px",
                          textTransform: "uppercase",
                        }}
                        onClick={() => {
                          SelectContent({
                            category: {
                              categoryName: currentCategory?.name,
                              id: currentCategory?.id,
                            },
                            subcategory: {
                              subcategoryName: item?.name,
                              id: item?.id,
                            },
                          });
                          setSecondaryDrawerOpen(false);
                          setDrawerOpen(false);
                          return true;
                        }}
                      >
                        <img
                          alt={item.slug}
                          data-expand="20"
                          src={item.image}
                          data-src={item.image}
                          style={{
                            height: "38px",
                            width: "38px",
                            borderRadius: "19px",
                            marginRight: dir === "rtl" ? "-14px" : "10px",
                          }}
                        />
                        <ListItemText
                          primary={item.name}
                          sx={{
                            flexGrow: "1",
                            padding: "0 10px",
                            fontSize: "14px",
                            textAlign: dir === "rtl" ? "right" : "left",
                          }}
                        />
                        <KeyboardArrowRight
                          className="rtl-transform"
                          sx={{
                            color: "#000",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </ListItem>
                    </>
                  ))}
                  {index < category?.sub_category.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Box>
        </Drawer>
      </Drawer>
    </>
  );

  const pathWithoutLang = pathname.replace(/^\/[^/]+/, "");

  const isPathMatching =
    ["", "/", "/products", "/product-details"].includes(pathWithoutLang) ||
    /products\/.*/.test(pathWithoutLang) ||
    /product-details\/.*/.test(pathWithoutLang);

  const [timeLeft, setTimeLeft] = useState<number>(0);

  useEffect(() => {
    if (globalOffers) {
      const remainingSeconds = globalOffers.remaining_min * 60;
      const leftSeconds = globalOffers.left_min * 60;

      if (remainingSeconds > 0) {
        setTimeLeft(remainingSeconds);
      } else if (leftSeconds > 0) {
        setTimeLeft(leftSeconds);
      }
    }
  }, [globalOffers]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const formatTime = (timeInSeconds: number) => {
    const days = Math.floor(timeInSeconds / 86400); // 86400 seconds in a day
    const hours = Math.floor((timeInSeconds % 86400) / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return { days, hours, minutes, seconds };
  };

  const { days, hours, minutes, seconds } = formatTime(timeLeft);

  return (
    <AppBar
      elevation={0}
      position="sticky"
      style={{
        padding: 0,
        borderBottom: "0px",
      }}
    >
      <Box sx={{}}>
        {globalOffers &&
          (Number(globalOffers.left_min) > 0 ||
            Number(globalOffers.remaining_min) > 0) && (
            <div style={{ backgroundColor: globalOffers.color_code }}>
              <Container
                maxWidth={"xl"}
                sx={{
                  backgroundColor: globalOffers.color_code,
                }}
              >
                <Toolbar
                  variant="dense"
                  sx={{
                    paddingLeft: "0px !important",
                    paddingRight: "0px !important",
                    minHeight: "42px !important",
                  }}
                >
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid
                      item
                      display="flex"
                      alignItems={"center"}
                      justifyContent="center"
                      flexDirection={{ xs: "column", sm: "row" }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "10px", sm: "14px" },
                          fontWeight: "400",
                        }}
                        component="p"
                        align="center"
                      >
                        {globalOffers.heading}
                      </Typography>
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              mx: 2,
                              fontWeight: "500",
                              my: { xs: "3px", sm: 0 },
                              fontSize: { xs: "10px", sm: "14px" },
                              textAlign: "center",
                              lineHeight: "12px",
                            }}
                          >
                            {days.toString().padStart(2, "0")} <br /> days
                          </Typography>
                          <Divider
                            orientation="vertical"
                            sx={{
                              bgcolor: "#000",
                              width: "2px",
                              height: "30px",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              mx: 2,
                              fontWeight: "500",
                              my: { xs: "3px", sm: 0 },
                              fontSize: { xs: "10px", sm: "14px" },
                              textAlign: "center",
                              lineHeight: "12px",
                            }}
                          >
                            {hours.toString().padStart(2, "0")} <br /> hours
                          </Typography>
                          <Divider
                            orientation="vertical"
                            sx={{
                              bgcolor: "#000",
                              width: "2px",
                              height: "30px",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              mx: 2,
                              fontWeight: "500",
                              my: { xs: "3px", sm: 0 },
                              fontSize: { xs: "10px", sm: "14px" },
                              textAlign: "center",
                              lineHeight: "12px",
                            }}
                          >
                            {minutes.toString().padStart(2, "0")} <br /> mins
                          </Typography>
                          <Divider
                            orientation="vertical"
                            sx={{
                              bgcolor: "#000",
                              width: "2px",
                              height: "30px",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              mx: 2,
                              fontWeight: "500",
                              my: { xs: "3px", sm: 0 },
                              fontSize: { xs: "10px", sm: "14px" },
                              textAlign: "center",
                              lineHeight: "12px",
                            }}
                          >
                            {seconds.toString().padStart(2, "0")} <br /> secs
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Toolbar>
              </Container>
            </div>
          )}

        <div
          className={`header-container ${
            scrollDirection === "up" ? "show" : "hide"
          }`}
        >
          <div className="slide-content">
            <Container maxWidth={"xl"} sx={{}}>
              <Toolbar
                variant="dense"
                sx={{
                  paddingLeft: "0px !important",
                  paddingRight: "0px !important",
                  minHeight: "42px !important",
                }}
              >
                <Box sx={{ flexGrow: 1, alignItems: "center" }} />
                <Typography
                  onClick={() => changeLanguage("en")}
                  variant="subtitle2"
                  component={"a"}
                  sx={{
                    mr: 1,
                    cursor: "pointer",
                    textDecoration: "none",

                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "23.12px",
                    textAlign: "left",
                  }}
                >
                  English
                </Typography>
                <Typography
                  variant="subtitle1"
                  component={"div"}
                  sx={{ mr: 1 }}
                >
                  |
                </Typography>
                <Typography
                  onClick={() => changeLanguage("ar")}
                  variant="subtitle2"
                  component={"a"}
                  sx={{
                    mr: 1,
                    cursor: "pointer",
                    textDecoration: "none",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "23.12px",
                    textAlign: "left",
                  }}
                >
                  عربي
                </Typography>
              </Toolbar>
            </Container>
          </div>
        </div>
        <Container maxWidth="lg">
          <Toolbar variant="dense" disableGutters>
            <MenuBox
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{}}>
                <Box sx={{ display: { xs: "none", md: "flex" }, flexGrow: 1 }}>
                  {links.map(
                    (
                      { text, to }: { text: string; to: string },
                      index: number,
                      arr: any
                    ) => (
                      <Link
                        to={to}
                        key={index}
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        <Typography
                          noWrap
                          sx={{
                            marginRight:
                              dir === "rtl"
                                ? index === 0
                                  ? 0
                                  : 4
                                : arr.length - 1 === index
                                ? 0
                                : 4,
                            textDecoration:
                              pathname === to ? "underline" : "none",
                            cursor: "pointer",
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "inherit",
                          }}
                        >
                          {text}
                        </Typography>
                      </Link>
                    )
                  )}
                </Box>
                <Box sx={{ display: { xs: "flex", md: "none" } }}>
                  <IconButton
                    size="small"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer(true)}
                  >
                    <MenuIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => {
                      setSearch(true);
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                  <Box height={"20px"} width={"18px"} />
                </Box>
              </Box>
              <CenterBox
                onClick={() => navigate(CurrentLanguagePath(""))}
                sx={{ direction: "ltr", cursor: "pointer" }}
              >
                <img
                  src={require("../../src/assets/images/Neeshah-logo.png")}
                  alt="lusive"
                  style={{ height: 38 }}
                />
                {/* <img
                  src={require("../../src/assets/images/lusive.png")}
                  alt="lusive"
                  style={{ height: 23 }}
                /> */}
              </CenterBox>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <Button
                    variant="outlined"
                    size="small"
                    disableFocusRipple
                    disableTouchRipple
                    disableRipple
                    onClick={() => setSearch(true)}
                    endIcon={
                      <SearchIcon
                        style={{
                          height: "15px",
                          width: "15px",
                        }}
                      />
                    }
                    sx={{
                      textTransform: "none",
                      borderWidth: 0,
                      padding: "0px",

                      "&:hover": {
                        borderWidth: 0,
                        background: "transparent",
                      },
                      "& .MuiButton-outlined": {
                        borderWidth: 0,
                      },
                      "& .MuiSvgIcon-root": {
                        mx: "2px",
                      },
                    }}
                  >
                    Search
                  </Button>
                  <Typography
                    variant="subtitle1"
                    component={"div"}
                    sx={{ mx: "5px" }}
                  >
                    |
                  </Typography>
                </Box>
                <div style={{ display: "flex", flexWrap: "nowrap" }}>
                  {isLoggedIn && (
                    <IconButton
                      size="small"
                      sx={{
                        mx: dir === "rtl" ? "5px" : undefined,
                      }}
                      onClick={() => handleWishlist()}
                    >
                      <Like style={{ height: "20px", width: "20px" }} />
                    </IconButton>
                  )}

                  <IconButton
                    size="small"
                    onClick={handleClick}
                    sx={{
                      mx: "5px",
                    }}
                  >
                    <ProfileIcon style={{ height: "20px", width: "20px" }} />
                  </IconButton>
                  <StyledBadge
                    badgeContent={cartData.cart_items?.length}
                    variant="standard"
                    showZero={true}
                    sx={{ fontSize: "8px", mr: "5px" }}
                  >
                    <IconButton size="small" onClick={toggleShoppingBagDrawer}>
                      <CartIcon style={{ height: "20px", width: "20px" }} />
                    </IconButton>
                  </StyledBadge>
                </div>
              </Box>
            </MenuBox>
          </Toolbar>
        </Container>
        <div className="d-none d-sm-block">
          <Divider sx={{ height: "2px", backgroundColor: "#A4A1AA33" }} />
        </div>

        <div className="d-none d-sm-block">
          {isPathMatching && (
            <Toolbar
              variant="dense"
              sx={{
                paddingLeft: "0px !important",
                paddingRight: "0px !important",
              }}
              disableGutters
            >
              <Navbar categories={menuItems} />
            </Toolbar>
          )}
        </div>
      </Box>

      <ShoppingBagDrawer
        open={open}
        toggleDrawer={toggleShoppingBagDrawer}
        cartData={useCartStore.getState().cartData}
      />
      {renderDrawer}
      {renderProfileMenu()}
      {search && (
        <div
          className="search-mini_searchmini__qAJeI"
          style={{ alignItems: "center" }}
        >
          <Box className="backdrop-box">
            <Container maxWidth={"md"}>
              <IconButton
                onClick={handleSearchClose}
                style={{
                  position: "absolute",
                  top: 10,
                  [dir === "rtl" ? "left" : "right"]: 0,
                  color: "white",
                }}
              >
                <Close />
              </IconButton>
              <SearchBar onClose={handleSearchClose} />
            </Container>
          </Box>
        </div>
      )}
    </AppBar>
  );
}
