import { AxiosRequestConfig } from "axios";
import axiosInstance from "../apiClient";
import API from "../endpoints";

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  showLoader?: boolean;
}

export const productService = {
  getProducts: (data: any) => {
    return axiosInstance.post(API.PRODUCTS, data);
  },
  getFilters: (data: any) => {
    return axiosInstance.post(API.FILTER, data);
  },
  getProductDetails: (data: any) => {
    return axiosInstance.post(API.PRODUCT_DETAILS, data);
  },
  getRecentlyViewedProducts: (data: any) => {
    return axiosInstance.post(API.RECENTLY_VIEWED_PRODUCTS, data);
  },
  getBanner: (data: any) => {
    return axiosInstance.post(API.GET_BANNERS, data);
  },
  getReviewsAndRatings: (data: any) => {
    return axiosInstance.post(API.GET_REVIEW, data);
  },
  search: (data: any, config?: CustomAxiosRequestConfig) => {
    return axiosInstance.post(API.SEARCH, data, config);
  },
};
