import { Box, Divider, Grid, IconButton, useMediaQuery } from "@mui/material";
import { FooterHeading, FooterLink, RowBox } from "./Styles";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CurrentLanguagePath } from "../utils/helper";
import { homeService } from "../api/services/homeService";
import { useEffect, useState } from "react";
import { SocialLinks } from "../types/setting";
import { useSettingStore } from "../zustand/store/useSettingStore";

function Footer() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { i18n, t } = useTranslation();
  const dir = i18n.dir();
  const { socialLinks } = useSettingStore();
  return (
    <Grid container rowSpacing={1} sx={{ backgroundColor: "#131118", pb: 2 }}>
      {!isSmallScreen && (
        <Grid item md={4}>
          <Box
            sx={{
              margin: "20px",
            }}
            component={"div"}
          >
            <FooterHeading sx={{ fontWeight: "400" }}>
              {t("footer.connect_with_us")}
            </FooterHeading>
            <Divider color="#FFFFFF" />
            <Box
              sx={{
                display: "flex",
                alignItems: "end",
                justifyContent: dir === "rtl" ? "auto" : "flex-end",
                mt: 1,
              }}
              component={"div"}
            >
              {socialLinks?.snapchat && (
                <IconButton
                  component="a"
                  href={socialLinks && socialLinks?.snapchat}
                  target="_blank"
                  rel="noreferrer"
                  size="small"
                >
                  <img
                    src={require("../assets/images/snapchat.png")}
                    alt="snapchat"
                    style={{ height: "34px", width: "34px" }}
                  />
                </IconButton>
              )}

              {socialLinks?.twitter && (
                <IconButton
                  component="a"
                  href={socialLinks?.twitter}
                  target="_blank"
                  rel="noreferrer"
                  size="small"
                >
                  <img
                    src={require("../assets/images/twitter.png")}
                    alt="twitter"
                    style={{
                      width: "34px",
                      height: "34px",
                    }}
                  />
                </IconButton>
              )}

              {socialLinks?.tiktok && (
                <IconButton
                  component="a"
                  href={socialLinks?.tiktok || ""}
                  target="_blank"
                  rel="noreferrer"
                  size="small"
                >
                  <img
                    src={require("../assets/images/tiktok.png")}
                    alt="tiktok"
                    style={{ height: "34px", width: "34px" }}
                  />
                </IconButton>
              )}
              {socialLinks?.instagram && (
                <IconButton
                  component="a"
                  href={socialLinks?.instagram || ""}
                  target="_blank"
                  rel="noreferrer"
                  size="small"
                >
                  <img
                    src={require("../assets/images/instagram.png")}
                    alt="instagram"
                    style={{ height: "34px", width: "34px" }}
                  />
                </IconButton>
              )}
            </Box>
          </Box>
        </Grid>
      )}

      <Grid item md={4} sm={6} xs={6}>
        <Box sx={{ alignItems: "center", margin: "20px" }}>
          <FooterHeading
            sx={{
              fontSize: isSmallScreen ? "12px" : "18px",
              lineHeight: isSmallScreen ? "17px" : "30px",
              fontWeight: "400",
            }}
          >
            {t("footer.important_links")}
          </FooterHeading>
          <Divider color="#FFFFFF" />
          <Box
            sx={{
              display: "flex",
              // flexWrap: "wrap",
              // justifyContent: "center",
              flexDirection: "column",
              // alignItems: "end",

              mt: 1,
              textUnderlineOffset: "#fff",
              "& > :not(style) ~ :not(style)": {
                ml: 2,
                color: "#fff",
                fontSize: isSmallScreen ? "12px" : "18px",
                lineHeight: isSmallScreen ? "17px" : "30px",
              },
            }}
          >
            <FooterLink
              to={CurrentLanguagePath("shipping-policy")}
              sx={{
                fontSize: isSmallScreen ? "12px" : "18px",
                lineHeight: isSmallScreen ? "17px" : "30px",
              }}
            >
              {t("footer.shipping_policy")}
            </FooterLink>
            <FooterLink to={CurrentLanguagePath("return-policy")}>
              {t("footer.exchange_return_policy")}
            </FooterLink>
            <FooterLink to={CurrentLanguagePath("faq")}>
              {t("footer.frequent_questions")}
            </FooterLink>
            <FooterLink to={CurrentLanguagePath("blog")}>
              {t("footer.blog")}
            </FooterLink>
            <FooterLink to={CurrentLanguagePath("privacy-policy")}>
              {t("footer.privacy_policy")}
            </FooterLink>
            <FooterLink to={CurrentLanguagePath("terms-and-conditions")}>
              {t("footer.terms_conditions")}
            </FooterLink>
            <FooterLink to={CurrentLanguagePath("join-us")}>
              {t("footer.join_us")}
            </FooterLink>
            <Box sx={{ alignSelf: "end" }}>
              <RowBox
                sx={{
                  display: { xs: "flex", sm: "flex", md: "none" },
                  mt: 1,
                  mb: 1,
                }}
              >
                <img
                  src="https://www.lusiveksa.com/public/assets/images/brand/PayMethods.png"
                  alt="payment methods"
                  style={{ height: "16px" }}
                />
              </RowBox>
              <RowBox sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}>
                <img
                  src={require("../assets/images/footerLogo.png")}
                  alt=""
                  style={{ height: "36px", width: "36px" }}
                />
                <img
                  src={require("../assets/images/business.png")}
                  alt=""
                  style={{ height: "36px", width: "100px" }}
                />
              </RowBox>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item md={4} sm={6} xs={6} sx={{}}>
        <Box sx={{ margin: "20px", mb: isSmallScreen ? "0px" : "20px" }}>
          <FooterHeading
            sx={{
              fontSize: isSmallScreen ? "12px" : "18px",
              lineHeight: isSmallScreen ? "17px" : "30px",
              fontWeight: "400",
            }}
          >
            {t("footer.who_we_are")}
          </FooterHeading>
          <Divider color="#FFFFFF" />
          <FooterHeading
            sx={{
              textAlign: "start",
              fontSize: isSmallScreen ? "12px" : "18px",
              lineHeight: isSmallScreen ? "17px" : "30px",
              mt: 1,
              verticalAlign: "text-bottom",
              mb: isSmallScreen ? 0 : "10px",
            }}
          >
            {socialLinks?.whoWeAre || ""}
          </FooterHeading>
          <RowBox>
            <RowBox sx={{}}>
              <FooterHeading
                sx={{
                  fontSize: isSmallScreen ? "12px" : "18px",
                  lineHeight: "15px",
                  marginBottom: 0,
                }}
              >
                {t("footer.whatsapp")}
              </FooterHeading>
              {socialLinks?.whatsapp && (
                <IconButton
                  component="a"
                  href={socialLinks?.whatsapp}
                  target="_blank"
                  rel="noreferrer"
                  size="small"
                >
                  <img
                    src={require("../assets/images/whatsapp.png")}
                    alt="whatsapp"
                    style={{
                      height: isSmallScreen ? "18px" : "34px",
                      width: isSmallScreen ? "18px" : "34px",
                    }}
                  />
                </IconButton>
              )}
            </RowBox>
            <RowBox>
              <FooterHeading
                sx={{
                  fontSize: isSmallScreen ? "12px" : "18px",
                  lineHeight: isSmallScreen ? "17px" : "30px",
                  fontWeight: "400",
                  marginBottom: 0,
                }}
              >
                {t("footer.email")}
              </FooterHeading>
              {socialLinks?.mail && (
                <IconButton
                  component="a"
                  href={socialLinks?.mail}
                  target="_blank"
                  rel="noreferrer"
                  size="small"
                >
                  <img
                    src={require("../assets/images/email.png")}
                    alt="email"
                    style={{
                      height: isSmallScreen ? "18px" : "34px",
                      width: isSmallScreen ? "18px" : "34px",
                    }}
                  />
                </IconButton>
              )}
            </RowBox>
          </RowBox>
        </Box>
        <Box sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
          <img
            src="https://www.lusiveksa.com/public/assets/images/brand/PayMethods.png"
            alt="payment methods"
            style={{ height: "30px", marginLeft: "16px" }}
          />
        </Box>
        <RowBox
          sx={{ ml: 2, display: { xs: "none", sm: "none", md: "flex" }, mt: 2 }}
        >
          <img
            src={require("../assets/images/footerLogo.png")}
            alt="lusive"
            style={{ height: "98px", width: "98px" }}
          />
          <img
            src={require("../assets/images/business.png")}
            alt="saudi business center"
            style={{ height: "70px", width: "200px" }}
          />
        </RowBox>
        {isSmallScreen && (
          <Grid item md={4}>
            <Box
              sx={{
                margin: "20px",
                mt: 0,
              }}
              component={"div"}
            >
              <FooterHeading
                sx={{ fontSize: "12px", fontWeight: "400", mb: 0 }}
              >
                {t("footer.connect_with_us")}
              </FooterHeading>
              <Divider color="#FFFFFF" />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: dir === "rtl" ? "auto" : "flex-end",
                  mt: 1,
                }}
                component={"div"}
              >
                {socialLinks?.snapchat && (
                  <IconButton
                    component="a"
                    href={socialLinks?.snapchat}
                    target="_blank"
                    rel="noreferrer"
                    size="small"
                    style={{
                      width: "23px",
                      height: "18px",
                    }}
                  >
                    <img
                      src={require("../assets/images/snapchat.png")}
                      alt="sanpchat"
                      style={{
                        width: "23px",
                        height: "18px",
                      }}
                    />
                  </IconButton>
                )}

                {socialLinks?.twitter && (
                  <IconButton
                    component="a"
                    href={socialLinks?.twitter}
                    target="_blank"
                    rel="noreferrer"
                    size="small"
                    style={{
                      width: "23px",
                      height: "18px",
                    }}
                  >
                    <img
                      src={require("../assets/images/twitter.png")}
                      alt="twitter"
                      style={{
                        width: "23px",
                        height: "18px",
                      }}
                    />
                  </IconButton>
                )}

                <IconButton
                  component="a"
                  href={socialLinks?.tiktok}
                  target="_blank"
                  rel="noreferrer"
                  size="small"
                  style={{
                    width: "23px",
                    height: "18px",
                  }}
                >
                  <img
                    src={require("../assets/images/tiktok.png")}
                    alt="tiktok"
                    style={{
                      width: "23px",
                      height: "18px",
                    }}
                  />
                </IconButton>
                {socialLinks?.instagram && (
                  <IconButton
                    component="a"
                    href={socialLinks?.instagram}
                    target="_blank"
                    rel="noreferrer"
                    size="small"
                    style={{
                      width: "23px",
                      height: "18px",
                    }}
                  >
                    <img
                      src={require("../assets/images/instagram.png")}
                      alt="instagram"
                      style={{
                        width: "23px",
                        height: "18px",
                      }}
                    />
                  </IconButton>
                )}
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
export default Footer;
