import {
  Typography,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import COLORS from "../../../constant/colors";
import { ChevronLeft, Delete, KeyboardArrowRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import LeftMenuBar from "../../../components/LeftMenuBar";
import { useTranslation } from "react-i18next";
import { shippingService } from "../../../api/services/shippingService";
import ShowToast from "../../../utils/ShowToast";
import useAuthStore from "../../../zustand/store/user";
import { CurrentLanguagePath } from "../../../utils/helper";
import { Helmet } from "react-helmet";
import { useLanguageStore } from "../../../zustand/store/language";

interface AddressDetails {
  id: number;
  first_name: string;
  last_name: string;
  city: string;
  address: string;
  latitude: string;
  longitude: string;
  country_code: string;
  phone: string;
  is_default: boolean;
}

interface AddressCardProps {
  address: AddressDetails;
  navigate: any;
  direction: string;
  onDelete: (id: number) => void;
}

const AddressCard: React.FC<AddressCardProps> = ({
  address,
  navigate,
  direction,
  onDelete,
}) => {
  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: COLORS.BLACK,
        borderRadius: "2px",
        p: 1,
        position: "relative",
        width: "100%",
        height: "176px",
        backgroundColor: COLORS.WHITE,
      }}
    >
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: "500",
          lineHeight: "26.01px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "220px",
        }}
      >
        {`${address.first_name} ${address.last_name}`}
      </Typography>
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "300",
          lineHeight: "17.34px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "400px",
        }}
      >
        {address.address}
      </Typography>
      <Typography
        sx={{ fontSize: "12px", fontWeight: "300", lineHeight: "17.34px" }}
      >
        {address.city}
      </Typography>
      <Typography
        sx={{ fontSize: "12px", fontWeight: "300", lineHeight: "17.34px" }}
      >
        {address.country_code}
      </Typography>
      <Typography
        sx={{ fontSize: "12px", fontWeight: "300", lineHeight: "17.34px" }}
      >
        {address.phone}
      </Typography>
      {address.is_default && (
        <Box
          sx={{
            backgroundColor: "#A4A1AA33",
            width: "121px",
            padding: "2px",
            mt: "9px",
          }}
        >
          <Typography
            sx={{ fontSize: "12px", fontWeight: "500", lineHeight: "17.34px" }}
            textAlign={"center"}
          >
            DEFAULT ADDRESS
          </Typography>
        </Box>
      )}
      {!address.is_default && (
        <IconButton
          onClick={() => {
            onDelete(address.id);
          }}
          sx={{
            position: "absolute",
            top: "8px",
            right: direction === "rtl" ? "unset" : "12px",
            left: direction === "rtl" ? "12px" : "unset",
          }}
          size="small"
        >
          <Delete />
        </IconButton>
      )}
      <Box
        onClick={() => {
          navigate(CurrentLanguagePath(`account/address/edit/${address.id}`));
        }}
      >
        <KeyboardArrowRight
          className="rtl-transform"
          sx={{
            position: "absolute",
            top: "50%",
            right: direction === "rtl" ? "unset" : 16,
            left: direction === "rtl" ? 15 : "unset",
            transform: "translateY(-50%)",
          }}
        />
      </Box>
    </Box>
  );
};

const Address = () => {
  const isLoading = useAuthStore((state) => state.isLoading);
  const navigate = useNavigate();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const [addresses, setAddresses] = useState<AddressDetails[]>([]);
  const { language } = useLanguageStore();

  const getAddress = async () => {
    try {
      const response = await shippingService.getShippingAddress();
      if (response && response.status === 200) {
        setAddresses(response.data);
      } else {
        console.log("error message:-", response.data);
      }
    } catch (error: any) {
      console.log(error?.message);
    }
  };

  useEffect(() => {
    getAddress();
  }, [language]);

  const deleteAddress = async (id: number) => {
    try {
      const request = {
        shipping_address_id: id,
      };
      const response = await shippingService.deleteShippingAddress(request);
      if (response && response.status === 200) {
        ShowToast((response as any)?.message, "success");
        getAddress();
      } else {
        console.log("error message:-", response.data);
      }
    } catch (error: any) {
      console.log(error?.message);
    }
  };

  return (
    <Box sx={{ background: "rgb(164 161 170 / 20%)", minHeight: "100vh" }}>
      <Helmet>
        <title>Address</title>
        <meta
          name="description"
          content="Manage your shipping and billing addresses."
        />
        <meta
          name="keywords"
          content="address, shipping address, billing address, e-commerce"
        />
        <link rel="canonical" href="http://mysite.com/account/address" />
      </Helmet>

      <Container
        maxWidth={"xl"}
        sx={{ paddingLeft: direction === "rtl" ? "24px" : "16px" }}
      >
        <Grid
          container
          pb={8}
          sx={{
            paddingLeft: { xs: "0%", sm: "0%" },
            paddingRight: { xs: "0%", sm: "0%" },
            marginLeft: { xs: "0%", sm: "0%" },
            marginRight: { xs: "0%", sm: "0%" },
            paddingTop: { xs: "0%", sm: "2%" },
          }}
          columns={12}
        >
          <LeftMenuBar selectedItem={2} />

          <Grid item xs={12} lg={8} md={8} gap={0}>
            {isSmall && (
              <Box mb={2} display={"flex"} alignItems={"center"}>
                <IconButton
                  onClick={() => navigate(CurrentLanguagePath("account"))}
                  sx={{
                    [direction === "rtl" ? "marginRight" : "marginLeft"]:
                      "-10px",
                    padding: "0px !important",
                    fontSize: "20px",
                    width: "25px",
                  }}
                >
                  <ChevronLeft className="rtl-transform" />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "26.01px",
                  }}
                >
                  {t("common.overview")}
                </Typography>
              </Box>
            )}
            {addresses.length > 0 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "500",
                      lineHeight: "26.01px",
                    }}
                    mb={1}
                  >
                    {t("address.my_addresses")}
                  </Typography>
                  <Button
                    size="small"
                    onClick={() => {
                      navigate(CurrentLanguagePath("account/address/new"));
                    }}
                    variant="contained"
                    sx={{
                      borderRadius: "0px",
                      mr: { xs: "unset", sm: "23px" },
                    }}
                  >
                    {t("address.addAddress")}
                  </Button>
                </Box>
                <Grid
                  container
                  spacing={{ xs: 0, sm: 3 }}
                  sx={{ width: "100% !important", direction: direction }}
                >
                  {addresses.map((address, index) => (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      sx={{ mb: { xs: "10px", sm: "unset" } }}
                    >
                      <AddressCard
                        address={address}
                        navigate={navigate}
                        direction={direction}
                        onDelete={deleteAddress}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}

            {addresses.length === 0 && !isLoading && (
              <Box
                component={"div"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  textAlign: "center",
                  p: 3,
                  backgroundColor: COLORS.WHITE,
                  borderRadius: "2px",
                  borderColor: COLORS.BLACK,
                }}
              >
                <Typography variant="h6" fontWeight={"600"} mb={1}>
                  {t("address.noAddress")}
                </Typography>
                <Typography variant="body2" mb={2}>
                  {t("address.addressDescription")}
                </Typography>
                <Button
                  onClick={() =>
                    navigate(CurrentLanguagePath("account/address/new"))
                  }
                  variant="contained"
                  sx={{
                    borderRadius: "0px",
                    padding: { xs: "8px 86px", sm: "10px 140px" },
                  }}
                >
                  {t("address.addAddress")}
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Address;
