import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Rating,
  IconButton,
  Container,
  Avatar,
} from "@mui/material";
import { AddPhotoAlternate } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import ShowToast from "../utils/ShowToast";
import { ReviewData } from "../types/checkout";
import { useParams } from "react-router";
import { orderService } from "../api/services/orderService";

const ReviewOrderPage: React.FC = () => {
  const { i18n, t } = useTranslation();
  const direction = i18n.dir();
  const margin = direction === "ltr" ? "marginRight" : "marginLeft";
  const [reviewOrder, setReviewOrder] = useState<ReviewData | null>(null);
  const { orderId: order_id } = useParams();
  const [rating, setRating] = useState<number | null>(null);
  const [description, setDescription] = useState<string>("");
  const [itemsReview, setItemsReview] = useState<any[]>([]);
  const [hoverFile, setHoverFile] = useState<any | null>(null);
  const fileInputField = useRef<any>(null);

  const fetchReviewItems = useCallback(async () => {
    try {
      const response = await orderService.getOderReviewItems({ order_id });
      if (response && response.status === 200) {
        setReviewOrder(response.data);
        if (response.data.review) {
          setRating(response.data.review.rating);
          setDescription(response.data.review.description);
        }

        const fetchedItemsReview = response.data.items
          .filter((item: any) => Number(item.rating) > 0)
          .map((item: any) => ({
            product_variant_id: item.product_variant_id,
            rating: Number(item.rating),
            description: item.description || "",
            attachments: item.old_attachments || [],
          }));

        setItemsReview(fetchedItemsReview);
      }
    } catch (err: any) {
      ShowToast(err.message, "error");
    }
  }, [order_id]);

  useEffect(() => {
    if (order_id) {
      fetchReviewItems();
    }
  }, [order_id, fetchReviewItems]);

  const handleFileChange = (index: number, item: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);

      setItemsReview((prev) => {
        const newReviewItems = [...prev];
        const itemIndex = newReviewItems.findIndex(
          (review) => review.product_variant_id === item.product_variant_id
        );
        console.log(itemIndex, 'itemIndexitemIndexitemIndexitemIndexitemIndex')
        if (itemIndex !== -1) {
          // Update existing review item's attachments
          newReviewItems[itemIndex].attachments.push(...newFiles);
        } else {
          console.log({
            product_variant_id: item.product_variant_id,
            rating: 0,
            description: "",
            attachments: newFiles,
          })
          // Initialize a new review item with product_variant_id and new files
          newReviewItems.push({
            product_variant_id: item.product_variant_id,
            rating: 0,
            description: "",
            attachments: newFiles,
          });
        }

        return newReviewItems;
      });
    }
  };
  const handleFileRemove = (itemIndex: number, file: any) => {
    setItemsReview((prev) => {
      const newReviewItems = [...prev];
      newReviewItems[itemIndex].attachments = newReviewItems[
        itemIndex
      ].attachments.filter((attachment: any) => {
        // Check if the attachment has an ID and match accordingly
        if (attachment.id) {
          return attachment.id !== file.id; // Compare by ID if it exists
        } else {
          return attachment.name !== file.name;
        }
      });
      return newReviewItems;
    });
  };

  console.log(itemsReview, "itemsReviewitemsReview");

  const reviewOrderSubmit = async () => {
    if (!rating?.toString()) {
      return ShowToast("Please fill in the rating.", "error");
    }

    if (!itemsReview.length) {
      return ShowToast(
        "At least one item must have a rating for the review.",
        "error"
      );
    }

    const hasInvalidItems = itemsReview.some(
      (item) =>
        (item.description ||
          (item.attachments && item.attachments.length > 0)) &&
        item.rating === 0
    );

    if (hasInvalidItems) {
      return ShowToast(
        "Please provide a rating for all items with a description or attachments.",
        "error"
      );
    }

    const formData = new FormData();
    formData.append("order_id", order_id || "");
    formData.append("rating", rating?.toString() || "");
    formData.append("description", description);

    itemsReview &&
      itemsReview.length > 0 &&
      itemsReview.forEach((item, index) => {
        console.log(item, "itemitemitemitemitem");
        if (item?.attachments && item.attachments.length > 0) {
          item.attachments.forEach(
            (attachment: any, attachmentIndex: number) => {
              if (attachment.id) {
                formData.append(
                  `items[${index}][old_attachments_ids][${attachmentIndex}]`,
                  attachment.id.toString()
                );
              } else {
                formData.append(
                  `items[${index}][attachments][${attachmentIndex}]`,
                  attachment
                );
              }
            }
          );
        }

        formData.append(
          `items[${index}][product_variant_id]`,
          item.product_variant_id
        );
        formData.append(`items[${index}][rating]`, item.rating.toString());
        formData.append(`items[${index}][description]`, item.description);
      });

    try {
      const response: any = await orderService.orderReview(formData);
      if (response && response.status === 200) {
        ShowToast(
          response.message || "Review submitted successfully!",
          "success"
        );
        fetchReviewItems();
      }
    } catch (error: any) {
      ShowToast(
        error.message || "Failed to submit review. Please try again.",
        "error"
      );
    }
  };

  return (
    <Container maxWidth={"sm"} sx={{ my: 5, minHeight: "100vh" }}>
      <Box sx={{ px: "5px" }}>
        <Typography variant="h6" textAlign="center" fontWeight="bold">
          {t("order.heading")} #{order_id}
        </Typography>
        <Typography variant="body2" textAlign="center" sx={{ mb: 3 }}>
          {t("order.subHeading")}
        </Typography>

        <Box sx={{ mb: 4 }}>
          <Typography textAlign="center" variant="h6">
            {t("order.question")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 1,
            }}
          >
            <Rating
              name="simple-controlled"
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
            />
          </Box>
          <TextField
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            multiline
            placeholder={t("order.commentPlaceholder")}
            variant="outlined"
            sx={{
              mt: 2,
              "& .MuiInputBase-root": { padding: "8px 14px !important" },
            }}
          />
        </Box>

        <Typography variant="h6" fontWeight="bold">
          *{t("order.rateItems")}:
        </Typography>
        {reviewOrder?.items.map((item, index) => {
          const existingReview = itemsReview?.find(
            (review) => review.product_variant_id === item.product_variant_id
          );
          return (
            <Box key={index} sx={{ mb: 4 }}>
              <Box sx={{ display: "flex", alignItems: "flex-start", mt: 2 }}>
                <img
                  src={item?.product_image_url}
                  loading="lazy"
                  alt="Item"
                  style={{
                    width: "107px",
                    height: "159px",
                    [margin]: "15px",
                  }}
                />
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="body2">
                    <strong>{item?.vendor_name}</strong>
                  </Typography>
                  <Typography>{item?.product_name}</Typography>
                  <Typography variant="body2">
                    <strong>Color:</strong> {item?.color_name} <br />
                    <strong>Size:</strong> {item?.size}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                    }}
                  >
                    <Rating
                      name={`item-rating-${index}`}
                      value={Number(existingReview?.rating) || 0}
                      onChange={(event, newValue) => {
                        setItemsReview((prev) => {
                          const newReviewItems = [...prev];
                          const itemIndex = newReviewItems.findIndex(
                            (review) =>
                              review.product_variant_id ===
                              item.product_variant_id
                          );
                          if (itemIndex !== -1) {
                            newReviewItems[itemIndex].rating = newValue || 0;
                          } else {
                            newReviewItems.push({
                              product_variant_id: item.product_variant_id,
                              rating: newValue || 0,
                              description: "",
                              attachments: [],
                            });
                          }
                          return newReviewItems;
                        });
                      }}
                    />
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column", borderRadius: '10px',
                        cursor: 'pointer'
                      }}
                    >
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                        sx={{
                          borderWidth: 1,
                          borderColor: "red",
                          padding: "4px",
                        }}
                      >
                        <input
                          accept="image/*,video/*"
                          multiple
                          type="file"
                          onChange={handleFileChange(index, item)}
                          style={{ display: "none" }}
                        ></input>
                        <AddPhotoAlternate
                          sx={{ width: "30px", height: "30px" }}
                        />
                      </IconButton>
                      <Typography sx={{ fontSize: "13px" }}>
                        Add Photo
                      </Typography>
                    </div>
                  </Box>
                </Box>
              </Box>
              {existingReview && existingReview?.attachments.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "nowrap",
                    overflowX: "auto",
                    mt: 2,
                    position: "relative",
                    height: "135px",
                    alignItems: "center",
                  }}
                >
                  {existingReview && existingReview?.attachments.length > 0 &&
                    existingReview?.attachments?.map(
                      (file: any, fileIndex: number) => {
                        let itemUrl = file?.id
                          ? file.url
                          : URL.createObjectURL(file);
                        let hoverFileName = file?.id ? file.url : file?.name;
                        return (
                          <Box
                            key={fileIndex}
                            onMouseEnter={() => setHoverFile(hoverFileName)}
                            onMouseLeave={() => setHoverFile(null)}
                            sx={{
                              position: "relative",
                              width: "100px",
                              height: "100px",
                              marginRight: "10px",
                              marginBottom: "10px",
                              marginTop: "10px",
                            }}
                          >
                            {hoverFile === hoverFileName && (
                              <CloseIcon
                                onClick={() => handleFileRemove(index, file)}
                                sx={{
                                  width: "24px",
                                  height: "24px",
                                  border: "solid #a5a2a2 1px",
                                  borderRadius: "50%",
                                  padding: "2px",
                                  position: "absolute",
                                  right: "0px",
                                  top: "-9px",
                                  cursor: "pointer",
                                  zIndex: "999",
                                }}
                              />
                            )}
                            {file.type.startsWith("image") ? (
                              <Avatar
                                sx={{
                                  objectFit: "cover",
                                  width: "100px",
                                  height: "100px",
                                  border: "solid #dadada 1px",
                                  borderRadius: "10px",
                                }}
                                src={itemUrl}
                                alt={`Selected file ${fileIndex}`}
                              />
                            ) : (
                              <video
                                src={itemUrl}
                                controls
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "cover",
                                  border: "solid #dadada 1px",
                                  borderRadius: "10px",
                                }}
                              />
                            )}
                          </Box>
                        );
                      }
                    )}
                </Box>
              )}

              <TextField
                fullWidth
                multiline
                placeholder={t("order.commentPlaceholder")}
                variant="outlined"
                value={existingReview?.description || ""}
                onChange={(e) => {
                  const newDescription = e.target.value;
                  setItemsReview((prev) => {
                    const newReviewItems = [...prev];
                    const itemIndex = newReviewItems.findIndex(
                      (review) =>
                        review.product_variant_id === item.product_variant_id
                    );

                    if (itemIndex !== -1) {
                      newReviewItems[itemIndex].description = newDescription;
                    } else {
                      newReviewItems.push({
                        product_variant_id: item.product_variant_id,
                        rating: 0,
                        description: newDescription,
                        attachments: [],
                      });
                    }
                    return newReviewItems;
                  });
                }}
                sx={{
                  mb: 4,
                  mt: 2,
                  "& .MuiInputBase-root": { padding: "8px 14px !important" },
                }}
              />
            </Box>
          );
        })}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: 4,
          }}
        >
          <Button
            sx={{ width: "100%", borderRadius: "0px !important" }}
            variant="contained"
            color="primary"
            onClick={reviewOrderSubmit}
          >
            {t("order.submitReview")}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ReviewOrderPage;
