import React from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { textFieldStyles } from "../../checkout/styles";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { CurrentLanguagePath } from "../../../utils/helper";
import { userService } from "../../../api/services/userService";
import ShowToast from "../../../utils/ShowToast";
import { GenerateLead } from "../../../tagManager/gtag";

interface FormValues {
  brandName: string;
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  password: string;
  link: string;
  terms: boolean;
  brandLogo: File | null;
}

const JoinUs: React.FC = () => {
  const { t } = useTranslation();
  const initialValues: FormValues = {
    brandName: "",
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    password: "",
    link: "",
    terms: false,
    brandLogo: null,
  };

  const validationSchema = Yup.object({
    brandName: Yup.string().required(t("errors.brandName")),
    firstName: Yup.string().required(t("errors.firstName")),
    lastName: Yup.string().required(t("errors.lastName")),
    mobile: Yup.string()
      .matches(/^5\d{8}$/, t("errors.mobile.invalid"))
      .required(t("errors.mobile.required")),
    email: Yup.string()
      .required(t("errors.email.required"))
      .email(t("errors.email.invalid"))
      .matches(
        /^[a-zA-Z0-9._%+-]+@(gmail\.com|yahoo\.com|hotmail\.com|icloud\.com|outlook\.com)$/,
        t("errors.email.invalid")
      ),
    password: Yup.string()
      .min(8, t("errors.password.min"))
      .required(t("errors.password.required")),
    link: Yup.string()
      .url(t("errors.link.invalid"))
      .required(t("errors.link.required")),
    terms: Yup.bool().oneOf([true], t("errors.terms")),
    brandLogo: Yup.mixed()
      .required(t("errors.brandLogo.required"))
      .test("fileSize", t("errors.brandLogo.fileSize"), (value: any) => {
        return value && value.size <= 2 * 1024 * 1024; // 2 MB
      }),
  });

  const handleSubmit = async (values: FormValues) => {
    let formData = new FormData();
    formData.append("brand_name", values.brandName);
    formData.append("first_name", values.firstName);
    formData.append("last_name", values.lastName);
    formData.append("phone", values.mobile);
    formData.append("email", values.email);
    formData.append("password", values.password);
    formData.append("logo", values.brandLogo as any);
    formData.append("social_link", values.link);
    try {
      const response: any = await userService.joinUs(formData);
      const { data } = response;
      if (response && response.status === 200) {
        GenerateLead({
          currency: "SAR",
          value: 20,
          lead_source: "google.com",
        });
        window.location.href = CurrentLanguagePath("join-us-success");
      } else {
        console.log("error message:- ", response);
      }
    } catch (error: any) {
      ShowToast(error.message, "error", "bottom-left");
      console.log(error.message);
    }
  };

  const direction = i18next.dir();

  return (
    <Container maxWidth="sm" sx={{ minHeight: "100vh", pt: 4, pb: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        {t("joinUs.heading")}
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        {t("joinUs.subHeading")}
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          setFieldValue,
          handleBlur,
          handleChange,
          values,
          touched,
          errors,
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {t("joinUs.brandName")}
                </Typography>
                <Field
                  as={TextField}
                  fullWidth
                  label=""
                  name="brandName"
                  placeholder={t("joinUs.brandNamePlaceholder")}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.brandName}
                  error={touched.brandName && !!errors.brandName}
                  helperText={<ErrorMessage name="brandName" />}
                  sx={textFieldStyles(direction)}
                  title={t("joinUs.brandName")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {t("joinUs.firstName")}
                </Typography>
                <Field
                  as={TextField}
                  fullWidth
                  label=""
                  name="firstName"
                  placeholder={t("joinUs.firstNamePlaceholder")}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  error={touched.firstName && !!errors.firstName}
                  helperText={<ErrorMessage name="firstName" />}
                  sx={textFieldStyles(direction)}
                  title={t("joinUs.firstName")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {t("joinUs.lastName")}
                </Typography>
                <Field
                  as={TextField}
                  fullWidth
                  label=""
                  name="lastName"
                  placeholder={t("joinUs.lastNamePlaceholder")}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  error={touched.lastName && !!errors.lastName}
                  helperText={<ErrorMessage name="lastName" />}
                  sx={textFieldStyles(direction)}
                  title={t("joinUs.lastName")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {t("joinUs.mobile")}
                </Typography>
                <Field
                  as={TextField}
                  fullWidth
                  label=""
                  name="mobile"
                  placeholder="5XXXXXXXX"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.mobile}
                  error={touched.mobile && !!errors.mobile}
                  helperText={<ErrorMessage name="mobile" />}
                  sx={textFieldStyles(direction)}
                  inputProps={{
                    maxLength: 9,
                    inputMode: "numeric",
                  }}
                  title={t("joinUs.mobile")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {t("joinUs.email")}
                </Typography>
                <Field
                  as={TextField}
                  fullWidth
                  label=""
                  name="email"
                  placeholder={t("joinUs.email")}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={touched.email && !!errors.email}
                  helperText={<ErrorMessage name="email" />}
                  sx={textFieldStyles(direction)}
                  title={t("joinUs.email")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {t("joinUs.password")}
                </Typography>
                <Field
                  as={TextField}
                  fullWidth
                  label=""
                  name="password"
                  type="password"
                  variant="outlined"
                  placeholder={t("joinUs.password")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  error={touched.password && !!errors.password}
                  helperText={<ErrorMessage name="password" />}
                  sx={textFieldStyles(direction)}
                  title={t("joinUs.password")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {t("joinUs.instagramOrTiktokLink")}
                </Typography>
                <Field
                  as={TextField}
                  fullWidth
                  // label={t('joinUs.instagramOrTiktokLink')}
                  label=""
                  name="link"
                  placeholder={t("joinUs.instagramOrTiktokLink")}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.link}
                  error={touched.link && !!errors.link}
                  helperText={<ErrorMessage name="link" />}
                  sx={textFieldStyles(direction)}
                  title={t("joinUs.instagramOrTiktokLink")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {t("joinUs.brandLogo")}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(event) =>
                      setFieldValue(
                        "brandLogo",
                        event.currentTarget.files
                          ? event.currentTarget.files[0]
                          : null
                      )
                    }
                    title={t("joinUs.brandLogo")}
                  />
                </div>
                <ErrorMessage
                  name="brandLogo"
                  component="div"
                  className="error"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Field as={Checkbox} name="terms" color="default" />}
                  label={
                    <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                      {t("i_agree")}{" "}
                      <span style={{ fontSize: "16px", fontWeight: "700" }}>
                        <a
                          href={CurrentLanguagePath("terms-and-conditions")}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "#000", textDecoration: "underline" }}
                        >
                          {t("terms_and_conditions")}
                        </a>
                      </span>
                    </Typography>
                  }
                />
                <ErrorMessage name="terms" component="div" className="error" />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{
                    borderRadius: "0px",
                    height: "56px",
                    textTransform: "capitalize",
                  }}
                >
                  {t("joinUs.continueToRegister")}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default JoinUs;
