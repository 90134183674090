import React, { useEffect, useState } from "react";
import {
  CardMedia,
  Typography,
  Button,
  Box,
  Grid,
  Container,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { wishListService } from "../../api/services/wishListService";
import { Products } from "../../utils/type";
import CloseIcon from "@mui/icons-material/Close";
import ShowToast from "../../utils/ShowToast";
import { cartService } from "../../api/services/cartService";
import useAuthStore from "../../zustand/store/user";
import { useCartStore } from "../../zustand/store/cartData";
import { CurrentLanguagePath } from "../../utils/helper";
import { Helmet } from "react-helmet";
import { useLanguageStore } from "../../zustand/store/language";
import { AddToCart } from "../../tagManager/gtag";

interface WishlistProductProps {
  product: Products;
  direction: string;
  navigate: NavigateFunction;
  removeWishList: (wishListId: string) => void;
  getWishlist: () => void;
  onAddToCart: (
    productId: string,
    variantId: string,
    quantity: string,
    product: Products
  ) => void;
  index: number;
  translation: any;
}

const WishlistProduct: React.FC<WishlistProductProps> = ({
  product,
  direction,
  navigate,
  removeWishList,
  onAddToCart,
  getWishlist,
  index,
  translation,
}) => {
  // const [open, setOpen] = useState<number | null>(null);
  // const handleSizeOpen = (index: number) => setOpen(index);
  // const handleSizeClose = () => setOpen(null);

  // const handleSizUpdate = async (old_slug: string, new_slug: string) => {
  //   handleSizeClose();
  //   const request = {
  //     type: "wishlist",
  //     old_slug: old_slug,
  //     new_slug: new_slug,
  //   };

  //   try {
  //     const response: any = await cartService.updateCartSize(request);
  //     const { data } = response;
  //     if (response && response.status === 200) {
  //       await getWishlist();
  //     } else {
  //       console.log("error message:- ", response);
  //     }
  //   } catch (error: any) {
  //     ShowToast(error.message, "error", "bottom-left");
  //     console.log(error.message);
  //   }
  // };

  return (
    <Box
      sx={{
        maxWidth: 200,
        margin: { xs: "6px", md: "12px" },
        marginBottom: { xs: "10px", md: "18px" },
      }}
    >
      <Helmet>
        <title>Wishlist - LUSIVE</title>
        <meta
          name="description"
          content="Items you have added to your wishlist. Save your favorites for later."
        />
        <meta
          name="keywords"
          content="wishlist, save for later, favorites, e-commerce"
        />
      </Helmet>

      <Box sx={{ position: "relative" }}>
        <div style={{ height: "249px" }}>
          <CardMedia
            onClick={() => {
              navigate(
                CurrentLanguagePath(`product-details/${product.product_slug}`)
              );
            }}
            component="img"
            image={product.linkImg}
            alt={product.product_title}
            sx={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </div>
        <CloseIcon
          onClick={() => removeWishList(product.wishlist_id)}
          sx={{
            position: "absolute",
            top: 8,
            right: direction === "rtl" ? "unset" : 8,
            left: direction === "rtl" ? 8 : "unset",
            color: "white",
            backgroundColor: "rgba(0,0,0,0.5)",
            borderRadius: "50%",
            padding: 0.5,
            cursor: "pointer",
          }}
        />
      </Box>
      <Box sx={{ mb: "5px" }}>
        <Typography
          gutterBottom
          component="p"
          fontSize={"16px"}
          lineHeight={"20px"}
          fontWeight={"300"}
          mt={"8px"}
          sx={{
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            maxHeight: "calc(2  1.5  14px)",
            minHeight: "calc(2  1.5  14px)",
            lineHeight: "1.5",
            height: "48px",
          }}
        >
          {product.product_title}
        </Typography>

        <Box sx={{ display: "flex", alignItems: "baseline", marginTop: 1 }}>
          <Typography
            sx={{
              textDecoration: product.sale_price && "line-through",
              marginRight: direction === "ltr" ? 1 : "unset",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {product.price} {translation("common.sar")}
          </Typography>
          {product.sale_price && (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: "#E51C1C",
              }}
            >
              &nbsp;{product.sale_price} {translation("common.sar")}
            </Typography>
          )}
        </Box>
        {product.discountApplied && product.showCode ? (
          <Typography
            fontSize={"12px"}
            fontWeight={"300"}
            lineHeight={"17.34px"}
          >
            {`${product.discount} | ${translation("code")}: ${
              product.coupon_code
            }`}
          </Typography>
        ) : product.discountApplied ? (
          <Typography
            fontSize={"12px"}
            fontWeight={"300"}
            lineHeight={"17.34px"}
          >
            {product.discount}
          </Typography>
        ) : product.showCode ? (
          <Typography
            fontSize={"12px"}
            fontWeight={"300"}
            lineHeight={"17.34px"}
          >
            {`${translation("code")}: ${product.coupon_code}`}
          </Typography>
        ) : (
          // Add empty space when no discount or code is shown
          <Box sx={{ height: "17.34px" }} />
        )}
      </Box>
      <Box>
        {/* {product && product?.sizes && product?.sizes?.length > 0 && (
          <Select
            disabled={product.sizes.length === 1}
            fullWidth
            defaultValue=""
            open={open === index}
            onClose={handleSizeClose}
            onOpen={() => handleSizeOpen(index)}
            value={product.product_slug}
            inputProps={{ "aria-label": "Select Size" }}
            sx={{
              marginBottom: 2,
              height: "28px",
              mt: "5px",
              "& .MuiSelect-icon": {
                left: direction === "rtl" ? "7px" : "unset",
                right: direction === "rtl" ? "unset" : "7px",
              },
              "& .MuiOutlinedInput-input": {
                paddingRight: "12px !important",
              },
            }}
          >
            <MenuItem value="" disabled>
              Select Size
            </MenuItem>
            {product.sizes?.map((item) => (
              <MenuItem
                key={item.size}
                value={item.product_slug}
                onClick={() =>
                  handleSizUpdate(product.product_slug, item.product_slug)
                }
              >
                {item.size}
              </MenuItem>
            ))}
          </Select>
        )} */}
        {/* <Select
          fullWidth
          defaultValue=""
          displayEmpty
          inputProps={{ "aria-label": "Select Size" }}
          sx={{
            marginBottom: 2,
            height: "28px",
            mt: "5px",
            "& .MuiSelect-icon": {
              left: direction === "rtl" ? "7px" : "unset",
              right: direction === "rtl" ? "unset" : "7px",
            },
            "& .MuiOutlinedInput-input": {
              paddingRight: "12px !important",
            },
          }}
        >
          <MenuItem value="" disabled>
            Select Size
          </MenuItem>
          {product.sizes.map((size) => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))}
        </Select> */}
        <Button
          onClick={() =>
            onAddToCart(
              product.product_id.toString(),
              product.product_variant_id.toString(),
              "1",
              product
            )
          }
          fullWidth
          variant="contained"
          sx={{
            backgroundColor: "#A4A1AA",
            borderRadius: 0,
            textTransform: "capitalize",
            background: "#A4A1AA !important",
            "&:hover": {
              background: "#000000 !important",
            },
          }}
        >
          Add to Bag
        </Button>
      </Box>
    </Box>
  );
};

function Wishlist() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { getCartItems } = useCartStore();
  const direction = i18n.dir();
  const [products, setProducts] = useState<Products[]>([]);
  const isLoading = useAuthStore((state) => state.isLoading);
  const { language } = useLanguageStore();

  const getWishList = async () => {
    try {
      const response = await wishListService.getWishList();
      if (response && response.status === 200) {
        setProducts(response.data.products);
      } else {
        console.log("error message:-", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getWishList();
  }, [language]);

  const removeWishList = async (wishListId: string) => {
    const request = {
      wishlist_id: wishListId,
    };
    try {
      const response: any = await wishListService.removeWishList(request);

      if (response && response.status === 200) {
        ShowToast(response.message, "success");
        getWishList();
      } else {
        console.log("error message:-", response);
      }
    } catch (error: any) {
      ShowToast(error.message, "error", "bottom-left");
      console.log(error);
    }
  };

  const addToCart = async (
    productId: string,
    variantId: string,
    quantity: string,
    product: Products
  ) => {
    const params = {
      product_id: productId,
      product_variant_id: variantId,
      quantity: quantity,
    };
    try {
      const response: any = await cartService.addToCart(params);
      if (response && response.status === 200) {
        ShowToast(response.data.message, "success");
        getCartItems();
        AddToCart({
          currency: "SAR",
          value: product.price,
          items: product,
        });
      } else {
        console.log("error message:-", response);
      }
    } catch (error: any) {
      ShowToast(error.message, "error", "bottom-left");
      console.log("Error adding to cart:", error);
    }
  };

  return (
    <Box sx={{ minHeight: "100vh" }}>
      {products.length > 0 ? (
        <>
          <Container maxWidth="lg">
            <Typography
              sx={{
                fontSize: "12px",
                mt: "15px",
                px: { xs: "6px", md: "33px" },
              }}
            >
              ITEMS ARE SAVED FOR 90 DAYS
            </Typography>
          </Container>
          <Container
            sx={{ flexGrow: 1, padding: 2, minHeight: "100vh" }}
            maxWidth={"md"}
          >
            <Grid container justifyContent="flex-start">
              {products.map((product, index) => (
                <Grid item key={index} xs={6} sm={3} md={3}>
                  <WishlistProduct
                    product={product}
                    direction={direction}
                    navigate={navigate}
                    removeWishList={removeWishList}
                    onAddToCart={addToCart}
                    getWishlist={getWishList}
                    index={index}
                    translation={t}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        </>
      ) : (
        !isLoading && (
          <Box>
            <Typography
              sx={{
                background: "#eff5f9",
                fontWeight: 700,
                margin: " 35px 0 15px",
                padding: "25px 15px",
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              You have no items in your wishlist.
            </Typography>
          </Box>
        )
      )}
    </Box>
  );
}

export default Wishlist;
