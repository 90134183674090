import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import useAuthStore from "../zustand/store/user";
import { getSessionId } from "../utils/helper";
import { apiBaseURL } from "../constant/baseUrl";
import { useLanguageStore } from "../zustand/store/language";

const axiosInstance = axios.create({
  baseURL: apiBaseURL,
  headers: {
    lng: useLanguageStore.getState().language,
    Accept: "application/json",
    sessionId: getSessionId(),
  },
});
axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig | any) => {
    if (config?.showLoader !== false) {
      useAuthStore.setState({ isLoading: true });
    }
    const token = useAuthStore.getState().token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    const language = useLanguageStore.getState().language;
    config.headers["lng"] = language;
    if (config.data && config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    if (config?.showLoader !== false) {
      useAuthStore.setState({ isLoading: true });
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    useAuthStore.setState({ isLoading: false });
    return response.data;
  },
  (error: AxiosError | any) => {
    useAuthStore.setState({ isLoading: false });
    if (error.response && error.response?.status === 401) {
      useAuthStore.getState().logout();
      window.location.href = "/";
    }
    return Promise.reject(error.response?.data);
  }
);

export default axiosInstance;
