import React, { useState } from "react";
import { CardMedia, Typography, Button, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Products } from "../utils/type";
import { CurrentLanguagePath } from "../utils/helper";

interface wishlistProps {
  data: Products;
  index: number;
  onAddToCart: (
    productId: string,
    variantId: string,
    quantity: string,
    product: Products
  ) => void;
  translation: any;
  handleWishlist?: () => void;
}

const WishlistProduct = ({
  data,
  index,
  onAddToCart,
  translation,
  handleWishlist,
}: wishlistProps) => {
  const { i18n } = useTranslation();
  const direction = i18n.dir();
  // const [open, setOpen] = useState<number | null>(null);
  // const handleSizeOpen = (index: number) => setOpen(index);
  // const handleSizeClose = () => setOpen(null);
  const [clientXonMouseDown, setClientXonMouseDown] = useState<number | null>(
    null
  );
  const [clientYonMouseDown, setClientYonMouseDown] = useState<number | null>(
    null
  );
  const handleOnMouseDown = (e: React.MouseEvent) => {
    setClientXonMouseDown(e.clientX);
    setClientYonMouseDown(e.clientY);
    e.preventDefault();
  };

  const handleOnClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (clientXonMouseDown !== e.clientX || clientYonMouseDown !== e.clientY) {
      e.preventDefault();
    }
  };

  let path = data.isAddedWishlist
    ? require("../assets/images/like.png")
    : require("../assets/images/unlike.png");

  // const handleSizUpdate = async (old_slug: string, new_slug: string) => {
  //   handleSizeClose();
  //   const request = {
  //     type: "wishlist",
  //     old_slug: old_slug,
  //     new_slug: new_slug,
  //   };

  //   try {
  //     const response: any = await cartService.updateCartSize(request);
  //     const { data } = response;
  //     if (response && response.status === 200) {
  //     } else {
  //       console.log("error message:- ", response);
  //     }
  //   } catch (error: any) {
  //     ShowToast(error.message, "error", "bottom-left");
  //     console.log(error.message);
  //   }
  // };

  return (
    <Box sx={{ maxWidth: 200, marginTop: 8, marginBottom: 8 }}>
      <Box sx={{ position: "relative" }}>
        <Link
          to={CurrentLanguagePath(`product-details/${data.product_slug}`)}
          onClick={handleOnClick}
          onMouseDown={handleOnMouseDown}
          draggable={false}
        >
          <div style={{ height: "249px" }}>
            <CardMedia
              component="img"
              image={data.linkImg}
              draggable={false}
              alt={data.product_title}
              sx={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </div>
        </Link>
        <Box
          sx={{
            position: "absolute",
            top: "5px",
            [direction === "rtl" ? "left" : "right"]: "5px",
            height: 40,
            width: 34,
            cursor: "pointer",
          }}
          onClick={() => {
            handleWishlist && handleWishlist();
          }}
        >
          <img
            alt="Wishlist Icon"
            src={path}
            style={{ width: "100%", height: "100%" }}
          />
        </Box>

        <Box>
          <Typography
            gutterBottom
            component="p"
            fontSize={"20px"}
            lineHeight={"20px"}
            fontWeight={"400"}
            mt={"8px"}
          >
            {data.vendor_name}
          </Typography>
          <Typography
            component="p"
            fontSize={"16px"}
            fontWeight={"300"}
            lineHeight={"23px"}
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              maxHeight: "calc(2  1.5  14px)",
              minHeight: "calc(2  1.5  14px)",
              lineHeight: "1.5",
              height: "48px",
            }}
          >
            {data.product_title}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "baseline", marginTop: 1 }}>
            <Typography
              sx={{
                textDecoration: data.sale_price && "line-through",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {data.price} SAR
            </Typography>
            {data.sale_price && (
              <Typography
                sx={{ fontSize: "14px", fontWeight: "500", color: "#E51C1C" }}
              >
                &nbsp;{data.sale_price} SAR&nbsp;
              </Typography>
            )}
          </Box>
          {data.discountApplied && data.showCode ? (
            <Typography
              fontSize={"12px"}
              fontWeight={"300"}
              lineHeight={"17.34px"}
            >
              {`${data.discount} | ${translation("code")}: ${data.coupon_code}`}
            </Typography>
          ) : data.discountApplied ? (
            <Typography
              fontSize={"12px"}
              fontWeight={"300"}
              lineHeight={"17.34px"}
            >
              {data.discount}
            </Typography>
          ) : data.showCode ? (
            <Typography
              fontSize={"12px"}
              fontWeight={"300"}
              lineHeight={"17.34px"}
            >
              {`${translation("code")}: ${data.coupon_code}`}
            </Typography>
          ) : (
            // Add empty space when no discount or code is shown
            <Box sx={{ height: "17.34px" }} />
          )}
        </Box>
        <Box>
          {/* {data && data?.sizes && data?.sizes?.length > 0 && (
            <Select
              disabled={data.sizes.length === 1}
              fullWidth
              defaultValue=""
              open={open === index}
              onClose={handleSizeClose}
              onOpen={() => handleSizeOpen(index)}
              value={data.product_slug}
              inputProps={{ "aria-label": "Select Size" }}
              sx={{
                marginBottom: 2,
                height: "28px",
                mt: "5px",
                "&:hover": {
                  backgroundColor: COLORS.WHITE,
                },
                "& .MuiSelect-icon": {
                  left: direction === "rtl" ? "7px" : "unset",
                  right: direction === "rtl" ? "unset" : "7px",
                },
                "& .MuiOutlinedInput-input": {
                  paddingRight: "12px !important",
                },
              }}
            >
              <MenuItem value="" disabled>
                Select Size
              </MenuItem>
              {data.sizes?.map((item) => (
                <MenuItem
                  key={item.size}
                  value={item.product_slug}
                  onClick={() =>
                    handleSizUpdate(data.product_slug, item.product_slug)
                  }
                >
                  {item.size}
                </MenuItem>
              ))}
            </Select>
          )} */}
          <Button
            fullWidth
            onClick={() =>
              onAddToCart(
                data.product_id.toString(),
                data.product_variant_id.toString(),
                "1",
                data
              )
            }
            variant="contained"
            color="primary"
            sx={{ backgroundColor: "#000", borderRadius: 0 }}
          >
            Add to Bag
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default WishlistProduct;
