import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Paper,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "./Button";
import ShowToast from "../utils/ShowToast";

interface NewUserCoupon {
  id: number;
  coupon_code: string;
  discount: string;
}

interface DiscountPopUpProps {
  open: boolean;
  handleClose: () => void;
  newUserCoupon: NewUserCoupon | null;
}

const DiscountPopUp: React.FC<DiscountPopUpProps> = ({
  open,
  handleClose,
  newUserCoupon,
}) => {
  const copyCouponCode = () => {
    if (newUserCoupon?.coupon_code) {
      navigator.clipboard
        .writeText(newUserCoupon.coupon_code)
        .then(() =>
          ShowToast(`${newUserCoupon.coupon_code} copied!`, "success")
        )
        .catch((error) => {
          console.error("Failed to copy text: ", error);
          ShowToast("Failed to copy", "error");
        });

      handleClose();
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "0px",
        },
      }}
    >
      {/* Close button at the top-right corner */}
      <IconButton
        disableRipple
        disableFocusRipple
        onClick={handleClose}
        sx={{
          justifyContent: "flex-end",
          padding: "0px",
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle>
        <Typography sx={{ fontSize: "28px", fontWeight: "500" }}>
          Leaving without your {newUserCoupon?.discount} code?
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
          Use this code at checkout to get {newUserCoupon?.discount} your order:
        </Typography>

        {/* Discount code section */}
        <Paper
          sx={{
            mt: 2,
            p: 2,
            textAlign: "center",
            border: "1px solid #e0e0e0",
            borderRadius: "4px",
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            {newUserCoupon?.coupon_code}
          </Typography>
        </Paper>
      </DialogContent>
      <DialogActions>
        {/* Action button */}
        <CustomButton
          onClick={copyCouponCode}
          variant="contained"
          fullWidth
          style={{
            marginBottom: 10,
            padding: 10,
            borderRadius: "0px",
          }}
        >
          Copy and go to cart
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default DiscountPopUp;
