import { create } from "zustand";
import { homeService } from "../../api/services/homeService";
interface GlobalOffers {
  heading: string;
  remaining_min: number;
  left_min: number;
  color_code: string;
}

interface SocialLinks {
  instagram: string | undefined;
  tiktok: string | undefined;
  twitter: string | undefined;
  snapchat: string | undefined;
  logo: string | undefined;
  whoWeAre: string | undefined;
  whatsapp: string | undefined;
  mail: string | undefined;
  contact: string | undefined;
}

interface SettingState {
  socialLinks: SocialLinks | null;
  globalOffers: GlobalOffers | null;
  isLoading: boolean;
  error: string | null;
  getSetting: () => Promise<void>;
}

export const useSettingStore = create<SettingState>((set) => ({
  socialLinks: null,
  globalOffers: null,
  isLoading: false,
  error: null,
  getSetting: async () => {
    set({ isLoading: true, error: null });
    try {
      const response = await homeService.getSetting();
      if (response.status === 200) {
        set({
          socialLinks: response.data.links,
          globalOffers: response.data.globalOffers,
          isLoading: false,
          error: null,
        });
      } else {
        set({ isLoading: false, error: "Failed to fetch settings" });
      }
    } catch (error: any) {
      set({
        isLoading: false,
        error: error.message || "Error fetching settings",
      });
    }
  },
}));
