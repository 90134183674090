const imagepath = {
  login: require("../assets/images/login.png"),
  signup: require("../assets/images/signup.png"),
  forgotPassword: require("../assets/images/forgotPassword.png"),
  logo: require("../assets/images/Neeshah-logo.png"),
  dress1: require("../assets/images/cardImg1.png"),
  visa: require("../assets/images/visa.png"),
  mada: require("../assets/images/mada.png"),
  masterCard: require("../assets/images/masterCard.png"),
  applePay: require("../assets/images/applePay.png"),
  cross: require("../assets/images/cross.png"),
  arrow: require("../assets/images/arrow.png"),
  zoom: require("../assets/images/zoom.png"),
  delivery: require("../assets/images/delivery.png"),
  background: require("../assets/images/background.png"),
  location: require("../assets/images/location.png"),
  calling: require("../assets/images/calling.png"),
  remove: require("../assets/images/remove.png"),
  headerLogo: require("../assets/images/headerLogo.png"),
  order: require("../assets/images/order.png"),
  thumb1: require("../assets/images/details1.png"),
  details1: require("../assets/images/details1.png"),
  dress4: require("../assets/images/dress4.png"),
  down: require("../assets/images/down.png"),
};
export default imagepath;
