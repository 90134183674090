import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import WishlistProduct from "./WishlistProduct";
import { Products } from "../utils/type";
import { ArrowBackIos, ArrowForwardIosOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

interface ProductCarouselProps {
  products: Products[];
  onAddToCart: (
    productId: string,
    variantId: string,
    quantity: string,
    product: Products
  ) => void;
  translation: any;
  handleWishlist?: (index: number) => void;
}

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 3000, min: 2000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 2000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 2,
  },
};

const RecentProductSlider: React.FC<ProductCarouselProps> = ({
  products,
  onAddToCart,
  translation,
  handleWishlist,
}) => {
  const { i18n } = useTranslation();
  const dir = i18n.dir();
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }: any) => {
    const {
      carouselState: { currentSlide, totalSlides },
    } = rest;

    return (
      <div
        className="carousel-button-group mb-4  gap-4 flex justify-end 
        items-center w-full"
      >
        <IconButton
          onClick={() => previous()}
          disableFocusRipple
          disableTouchRipple
          disableRipple
          sx={{
            [dir === "rtl" ? "right" : "left"]: "-32px",
            position: "absolute",
            top: "40%",
            zIndex: 10,
            padding: "0px !important",
            color: "#000 !important",
            display: { xs: "none", sm: "block", lg: "block" },
            "& .css-1j3sv1k-MuiSvgIcon-root": {
              fill: "#000 !important",
              color: "#000 !important",
            },
          }}
        >
          <ArrowBackIos className="rtl-transform" />
        </IconButton>
        <IconButton
          onClick={() => next()}
          disableFocusRipple
          disableTouchRipple
          disableRipple
          sx={{
            [dir === "ltr" ? "right" : "left"]: "-15px",
            position: "absolute",
            top: "40%",
            zIndex: 10,
            padding: "0px !important",
            color: "#000 !important",
            display: { xs: "none", sm: "block", lg: "block" },
            "& .css-1j3sv1k-MuiSvgIcon-root": {
              fill: "#000 !important",
              color: "#000 !important",
            },
          }}
        >
          <ArrowForwardIosOutlined className="rtl-transform" />
        </IconButton>
      </div>
    );
  };

  return products.length === 0 ? (
    <Outlet />
  ) : (
    <Carousel
      responsive={responsive}
      infinite
      rtl={dir === "rtl"}
      ssr={true}
      deviceType={"desktop"}
      itemClass="image-item"
      arrows={false}
      rewind={false}
      renderButtonGroupOutside={true}
      customButtonGroup={products?.length >= 4 ? <ButtonGroup /> : null}
    >
      {products.map((product, index) => (
        <WishlistProduct
          key={product.id}
          data={product}
          index={index}
          onAddToCart={onAddToCart}
          translation={translation}
          handleWishlist={() => handleWishlist && handleWishlist(index)}
        />
      ))}
    </Carousel>
  );
};

export default RecentProductSlider;
