import React from "react";
import Typography from "@mui/material/Typography";
import { Box, ButtonBase } from "@mui/material";
import { Link } from "react-router-dom";
import { StyledTypography } from "../utils/theme/styles/Common";
import { useTranslation } from "react-i18next";
import { Products } from "../utils/type";
import { CurrentLanguagePath } from "../utils/helper";
import ShowToast from "../utils/ShowToast";

interface ProductCardProps {
  data: Products;
  minWidth?: number | string;
  handleWishlist?: () => void;
}

export default function ProductCard({
  data,
  minWidth = "152.88px",
  handleWishlist,
}: ProductCardProps) {
  const { i18n, t } = useTranslation();
  const dir = i18n.dir();
  let path = data.isAddedWishlist
    ? require("../assets/images/like.png")
    : require("../assets/images/unlike.png");
  const [clientXonMouseDown, setClientXonMouseDown] = React.useState<
    number | null
  >(null);
  const [clientYonMouseDown, setClientYonMouseDown] = React.useState<
    number | null
  >(null);
  const handleOnMouseDown = (e: React.MouseEvent) => {
    setClientXonMouseDown(e.clientX);
    setClientYonMouseDown(e.clientY);
    e.preventDefault();
  };

  const handleOnClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (clientXonMouseDown !== e.clientX || clientYonMouseDown !== e.clientY) {
      e.preventDefault();
    }
  };

  const handleCouponClick = (e: React.MouseEvent) => {
    // e.stopPropagation();
    e.preventDefault();
    navigator.clipboard
      .writeText(data.coupon_code)
      .then(() => ShowToast(`${data.coupon_code} copied!`, "success"))
      .catch((error) => {
        console.error("Failed to copy text: ", error);
        ShowToast("Failed to copy", "error");
      });
  };

  return (
    <ButtonBase
      component={Link}
      sx={{
        backgroundColor: "#fff",
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
        maxWidth: "202px",
        marginLeft: "8px",
        marginRight: "8px",
        borderRadius: "0px",
        pb: "15px",
        height: "auto",
        minWidth: minWidth,
        "& .MuiTouchRipple-child": {
          backgroundColor: `#f7f7f7 !important`,
        },
      }}
      to={CurrentLanguagePath(`product-details/${data.product_slug}`)}
      draggable={false}
      onMouseDown={handleOnMouseDown}
      onClick={handleOnClick}
      className="hover-opacity"
    >
      <Box
        sx={{
          position: "absolute",
          top: "5px",
          [dir === "rtl" ? "left" : "right"]: "5px",
          height: 40,
          width: 34,
        }}
        onClick={(event) => {
          event.preventDefault();
          handleWishlist && handleWishlist();
        }}
      >
        <img
          alt="Wishlist Icon"
          src={path}
          style={{ width: "100%", height: "100%" }}
        />
      </Box>

      <div style={{ height: "249px", width: "100%" }}>
        <img
          alt={data.product_title}
          draggable={false}
          loading="lazy"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          src={data.linkImg}
        />
      </div>

      <Box sx={{ margin: 0, padding: 0, width: "100%" }}>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "14px", sm: "16px" },
            lineHeight: "23.12px",
            textAlign: dir === "rtl" ? "right" : "left",
            mt: 1,
            fontWeight: 400,
            fontStyle: "normal",
          }}
        >
          {data.vendor_name}
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "14px", sm: "16px" },
            fontWeight: "300",
            fontStyle: "normal",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            maxHeight: "calc(2  1.5  14px)",
            minHeight: "calc(2  1.5  14px)",
            lineHeight: "1.5",
            height: "48px",
          }}
        >
          {data.product_title}
        </Typography>
        {/* </Link> */}
        {data.sale_price ? (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  mr: "6px",
                  fontSize: { xs: "13px", sm: "14px" },
                  lineHeight: "23.12px",
                  textAlign: dir === "rtl" ? "right" : "left",
                  fontWeight: "500",
                  fontStyle: "normal",
                  mt: "6px",
                  textDecoration: "line-through",
                }}
              >
                {`${data.price} ${t("common.sar")}`}
              </Typography>
              <Typography
                sx={{
                  color: "#E51C1C",
                  mr: "6px",
                  fontSize: { xs: "13px", sm: "14px" },
                  lineHeight: "23.12px",
                  textAlign: dir === "rtl" ? "right" : "left",
                  fontWeight: "500",
                  fontStyle: "normal",
                  marginTop: "5px",
                }}
              >
                {`${data.sale_price}  ${t("common.sar")}`}
              </Typography>
            </Box>
            {data.discountApplied && data.showCode ? (
              <Box
                sx={{
                  backgroundColor: "#131118",
                  mt: 1,
                  cursor: "pointer",
                }}
                onClick={handleCouponClick}
              >
                <StyledTypography
                  title={data.coupon_code}
                  sx={{
                    color: "white",
                    fontSize: { xs: "13px", sm: "16px" },
                    lineHeight: "23.12px",
                    textAlign: dir === "rtl" ? "right" : "left",
                    fontWeight: "400",
                    fontStyle: "normal",
                  }}
                >
                  &nbsp;{`${data.discount} | ${data.coupon_code}`}
                </StyledTypography>
              </Box>
            ) : (
              <>
                {data.discountApplied ? (
                  <Box
                    sx={{
                      backgroundColor: "#131118",
                      mt: 1,
                    }}
                  >
                    <StyledTypography
                      sx={{
                        color: "white",
                        fontSize: { xs: "13px", sm: "16px" },
                        lineHeight: "23.12px",
                        textAlign: dir === "rtl" ? "right" : "left",
                        fontWeight: "400",
                        fontStyle: "normal",
                      }}
                    >
                      &nbsp;{data.discount}
                    </StyledTypography>
                  </Box>
                ) : (
                  data?.showCode && (
                    <Box
                      sx={{
                        backgroundColor: "#131118",
                        mt: 1,
                      }}
                      onClick={handleCouponClick}
                    >
                      <StyledTypography
                        sx={{
                          color: "white",
                          fontSize: { xs: "13px", sm: "16px" },
                          lineHeight: "23.12px",
                          textAlign: dir === "rtl" ? "right" : "left",
                          fontWeight: "400",
                          fontStyle: "normal",
                        }}
                      >
                        &nbsp;{`${data.coupon_code}`}
                      </StyledTypography>
                    </Box>
                  )
                )}
              </>
            )}
          </Box>
        ) : (
          <>
            <Typography
              sx={{
                fontSize: { xs: "13px", sm: "14px" },
                lineHeight: "23.12px",
                textAlign: dir === "rtl" ? "right" : "left",
                fontWeight: "500",
                mt: "6px",
              }}
            >
              {`${data.price} ${t("common.sar")}`}
            </Typography>
            {data.discountApplied && data.showCode ? (
              <Box
                sx={{
                  backgroundColor: "#131118",
                  mt: 1,
                }}
                onClick={handleCouponClick}
              >
                <StyledTypography
                  sx={{
                    color: "white",
                    fontSize: { xs: "13px", sm: "16px" },
                    lineHeight: "23.12px",
                    textAlign: dir === "rtl" ? "right" : "left",
                    fontWeight: "400",
                    fontStyle: "normal",
                  }}
                >
                  &nbsp;{`${data.discount} | ${data.coupon_code}`}
                </StyledTypography>
              </Box>
            ) : (
              <>
                {data.discountApplied ? (
                  <Box
                    sx={{
                      mt: 1,
                      backgroundColor: "#131118",
                    }}
                    onClick={handleCouponClick}
                  >
                    <StyledTypography
                      sx={{
                        color: "white",
                        width: "140px",
                        pl: "2px",
                        // pr:"2px",
                        fontSize: { xs: "13px", sm: "16px" },
                        lineHeight: "23.12px",
                        [dir === "rtl" ? "paddingRight" : "paddingLeft"]: "2px",
                        textAlign: dir === "rtl" ? "right" : "left",
                        fontWeight: "400",
                        fontStyle: "normal",
                      }}
                    >
                      &nbsp;{data.discount}
                    </StyledTypography>
                  </Box>
                ) : (
                  data?.showCode && (
                    <Box
                      sx={{
                        mt: 1,
                        backgroundColor: "#131118",
                      }}
                      onClick={handleCouponClick}
                    >
                      <StyledTypography
                        sx={{
                          color: "white",
                          width: "140px",
                          pl: "2px",
                          // pr:"2px",
                          fontSize: { xs: "13px", sm: "16px" },
                          lineHeight: "23.12px",
                          [dir === "rtl" ? "paddingRight" : "paddingLeft"]:
                            "2px",
                          textAlign: dir === "rtl" ? "right" : "left",
                          fontWeight: "400",
                          fontStyle: "normal",
                        }}
                      >
                        &nbsp;{`${data.coupon_code}`}
                      </StyledTypography>
                    </Box>
                  )
                )}
                <div style={{ flex: 1 }}></div>
              </>
            )}
          </>
        )}
      </Box>
      <div></div>
    </ButtonBase>
  );
}
