import React, { useState } from "react";
import { Box, Container, Paper, Typography } from "@mui/material";
import { Link } from "react-alice-carousel";
import { CurrentLanguagePath } from "../utils/helper";
import { SelectContent } from "../tagManager/gtag";

interface Category {
  id: number;
  name: string;
  slug: string;
  types: {
    id: number;
    name: string;
    sub_category: {
      id: number;
      name: string;
      slug: string;
    }[];
  }[];
}
interface NavbarProps {
  categories: Category[];
}

const Navbar: React.FC<NavbarProps> = ({ categories }) => {
  const useStyles = {
    menuContent: {
      display: "flex",
      width: "100%",
      backColor: "red",
    },
  };

  const classes = useStyles;
  // const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [currentCategory, setCurrentCategory] = useState<Category | null>(null);

  const handleMenuOpen = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    category: Category
  ) => {
    // setOpenDropdown(category.name);
    setCurrentCategory(category);
  };

  const handleMenuClose = () => {
    // setOpenDropdown(null);
    setCurrentCategory(null);
  };

  return (
    <div onMouseLeave={handleMenuClose} style={{ flex: 1 }}>
      <Container
        maxWidth="lg"
        sx={{
          paddingLeft: "18px !important",
          paddingRight: "0px !important",
          alignItems: "flex-end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            padding: "5px",
            position: "relative",
            paddingBottom: "15px",
          }}
        >
          {categories.map((category, index) => (
            <div
              key={index}
              style={{ position: "relative", marginRight: "20px" }}
              // onClick={() => navigate(`products/${category?.slug}`, { replace: true })}
            >
              <Link
                href={CurrentLanguagePath(`products/${category?.slug}`)}
                onMouseOver={(e) => handleMenuOpen(e, category)}
                style={{
                  textDecoration: "none",
                  cursor: "pointer",
                  color: "inherit",
                }}
              >
                <Typography
                  variant="subtitle1"
                  noWrap
                  sx={{
                    fontSize: "16px",
                    fontWeight: "300",
                    lineHeight: "23.12px",
                    textAlign: "left",
                  }}
                >
                  {category.name}
                </Typography>
              </Link>
            </div>
          ))}
        </Box>
      </Container>
      {currentCategory && (
        <Paper
          onMouseLeave={handleMenuClose}
          sx={{
            position: "absolute",
            top: "90%",
            width: "100%",
            left: 0,
            borderRadius: 0,
            boxShadow: "0 15px 15px #00000024",
            paddingBottom: 10,
            paddingTop: "20px",
          }}
          elevation={0}
        >
          <Container maxWidth="lg" sx={{ paddingLeft: "20px !important" }}>
            <Box sx={classes.menuContent}>
              {currentCategory.types.map((type, index) => (
                <div key={index}>
                  <Typography
                    sx={{
                      marginBottom: "8px",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "23.12px",
                      width: "180px",
                      alignItems: "center",
                    }}
                  >
                    {type.name}
                  </Typography>
                  <Box sx={{ alignItems: "flex-start" }}>
                    {type.sub_category.map((subCategory, index) => (
                      <Link
                        href={CurrentLanguagePath(
                          `products/${currentCategory?.slug}?sub=${subCategory?.slug}`
                        )}
                        style={{ textDecoration: "none" }}
                      >
                        <Typography
                          key={index}
                          onClick={() => {
                            SelectContent({
                              category: {
                                categoryName: currentCategory?.name,
                                id: currentCategory?.id,
                              },
                              subcategory: {
                                subcategoryName: subCategory?.name,
                                id: subCategory?.id,
                              },
                            });
                            handleMenuClose();
                            // navigate(`/products/${currentCategory?.slug}?sub=${subCategory?.slug}`, { replace: true });
                          }}
                          sx={{
                            fontSize: "16px",
                            fontWeight: "300",
                            lineHeight: "23.12px",
                            cursor: "pointer",
                          }}
                        >
                          {subCategory.name}
                        </Typography>
                      </Link>
                    ))}
                  </Box>
                </div>
              ))}
            </Box>
          </Container>
        </Paper>
      )}
    </div>
  );
};

export default Navbar;
