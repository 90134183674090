import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  Link,
  InputAdornment,
  IconButton,
  Box,
} from "@mui/material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import imagepath from "../../../constant/imagepath";
import CustomLabel from "../../../components/Label";
import CustomTextField from "../../../components/TextField";
import CustomButton from "../../../components/Button";
import {
  BodyTypography,
  FormContainer,
  FormContainerWrapper,
  FormOptions,
  HeadingTypography,
  ImageContainer,
  LanguageBox,
  Logo,
  StyledImage,
} from "../styles/styles";
import { useTranslation } from "react-i18next";
import { Field, ErrorMessage, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { userService } from "../../../api/services/userService";
import ShowToast from "../../../utils/ShowToast";
import useAuthStore from "../../../zustand/store/user";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { CurrentLanguagePath } from "../../../utils/helper";
import { useLanguageStore } from "../../../zustand/store/language";
import { LogIn } from "../../../tagManager/gtag";

function Login() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const [isLoginActive, setIsLoginActive] = React.useState(false);
  const [rememberMe, setRememberMe] = useState(
    useAuthStore.getState().email ? true : false
  );
  const formikRef = useRef<FormikProps<any>>(null);
  const [isMobileNumber, setIsMobileNumber] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const phoneInputRef = useRef<HTMLInputElement>(null);
  const [customerId, setCustomerId] = useState<string | null>(null);
  const { language } = useLanguageStore();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email: string): boolean => {
    const re =
      /^[a-zA-Z0-9._%+-]+@(gmail\.com|yahoo\.com|hotmail\.com|icloud\.com|outlook\.com)$/;
    return re.test(email);
  };

  const validatePhone = (phone: string): boolean => {
    const re = /^5\d{8}$/;
    return re.test(phone);
  };

  const LoginSchema = Yup.object().shape({
    emailOrPhone: Yup.string()
      .required("Email or phone number is required")
      .test("emailOrPhone", "Invalid email or phone number", function (value) {
        if (typeof value !== "string") return false;
        return validateEmail(value) || validatePhone(value);
      }),
    password: Yup.string().when("emailOrPhone", {
      is: (value: string) => validatePhone(value),
      then: (schema) => schema.notRequired(),
      otherwise: () => Yup.string().required("Password is required"),
    }),
  });

  const handleEmailPhoneBlur = (values: any, setFieldValue: any) => {
    const { emailOrPhone } = values;
    const isEmail = validateEmail(emailOrPhone);
    const isPhone = validatePhone(emailOrPhone);
    setFieldValue("isEmail", isEmail);
    setFieldValue("isPhone", isPhone);
  };

  useEffect(() => {
    if (customerId) {
      const path = CurrentLanguagePath(`mobile-otp?id=${customerId}`);
      console.log("Navigating to:", path);
      navigate(path, {
        state: {
          from: "login",
        },
      });
      setCustomerId(null);
    }
  }, [customerId, navigate]);

  const handleLoginEmail = async (values: any) => {
    const request = {
      email: `${values.emailOrPhone}`.toLocaleLowerCase(),
      password: values.password,
    };
    try {
      const response: any = await userService.signIn(request);
      const { data } = response;
      if (response && response.status === 200) {
        ShowToast(response.message, "success");
        useAuthStore.getState().setUserInfo(data);
        useAuthStore
          .getState()
          .login(request.email, request.password, rememberMe);
        LogIn("manually");
        setTimeout(() => {
          navigate(CurrentLanguagePath(""));
        }, 100);
      } else {
        console.log("error message:- ", response);
      }
    } catch (error: any) {
      ShowToast(error.message, "error", "bottom-left");
      console.log(error.message);
    }
  };
  const handleLoginPhone = async (values: any) => {
    const request = {
      phone: values.emailOrPhone,
      country_code: "+966",
    };
    try {
      const response: any = await userService.phoneLogin(request);
      if (response && response.status === 200) {
        ShowToast(response.message, "success");
        setCustomerId(response.data.customer_id);
        // navigate(CurrentLanguagePath(`/mobile-otp?id=${response.data.customer_id}`), {
        //   state: {
        //     from: "login",
        //   },
        // });
      } else {
        console.log("error message:-", response);
      }
    } catch (error: any) {
      ShowToast(error.message, "error", "bottom-left");
      console.log(error);
    }
  };

  const handleSubmit = (values: any) => {
    const { isEmail } = values;
    if (isEmail) {
      handleLoginEmail(values);
    } else {
      handleLoginPhone(values);
    }
  };

  const { setLanguage } = useLanguageStore();
  const { pathname, search: query } = useLocation();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem("i18nextLng", language);
    const segments = pathname.split("/").filter(Boolean);
    const supportedLanguages = ["en", "ar"];
    if (supportedLanguages.includes(language)) {
      segments[0] = language;
    } else {
      segments.unshift(language);
    }
    const newPath = `/${segments.join("/")}${query || ""}`;
    setLanguage(language);
    document.body.dir = language === "ar" ? "rtl" : "ltr";
    document.body.setAttribute("dir", language === "ar" ? "rtl" : "ltr");
    navigate(newPath, { replace: true });
    // window.location.reload();
  };

  return (
    <Grid container sx={{ direction: language === "ar" ? "rtl" : "ltr" }}>
      <ImageContainer item xs={12} sm={12} md={7}>
        <StyledImage
          src={imagepath.login}
          style={{ maxWidth: "100%" }}
          alt="Login"
        />
        <Logo
          src={imagepath.logo}
          alt="Logo"
          dir={direction}
          onClick={() => navigate(CurrentLanguagePath(""))}
        />
      </ImageContainer>
      <FormContainerWrapper item xs={12} sm={12} md={5}>
        <LanguageBox dir={direction} sx={{ cursor: "pointer" }}>
          <Typography onClick={() => changeLanguage("en")}>English</Typography>{" "}
          <Typography sx={{ mx: 1 }}>|</Typography>
          <Typography onClick={() => changeLanguage("ar")}>عربي</Typography>
        </LanguageBox>

        <Formik
          innerRef={formikRef}
          initialValues={{
            emailOrPhone: useAuthStore.getState().email,
            password: useAuthStore.getState().password,
            isEmail: useAuthStore.getState().email?.length !== 0,
            isPhone: false,
            country_code: "+966",
          }}
          validationSchema={LoginSchema}
          onSubmit={handleSubmit}
          validateOnBlur={true}
          validateOnChange={true}
          enableReinitialize={true}
        >
          {({ values, handleSubmit, setFieldValue, errors, touched }) => (
            <FormContainer
              component="form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <HeadingTypography variant="h4">
                {t("welcomeBack")}
              </HeadingTypography>
              <BodyTypography variant="body1" gutterBottom>
                {isLoginActive ? t("desPassword") : t("description")}
              </BodyTypography>
              <FormControl fullWidth>
                {isMobileNumber && (
                  <Grid container spacing={1}>
                    <Grid item xs={4} sm={3}>
                      <FormControl fullWidth>
                        <CustomLabel>{t("countryCode")}</CustomLabel>
                        <Field
                          disabled
                          as={CustomTextField}
                          name="country_code"
                          variant="outlined"
                          fullWidth
                          error={touched.country_code && !!errors.country_code}
                          helperText={
                            touched.country_code && errors.country_code
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Box
                                  component="img"
                                  src="https://vogacloset.com/3.0.28/static/media/sa.svg"
                                  alt="Dubai Flag"
                                  sx={{ width: "20px", height: "auto" }}
                                />
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              paddingLeft: "8px",
                            },
                            "& .MuiInputAdornment-root": {
                              marginRight: "0px",
                            },
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#000000",
                            },
                            "& .MuiInputBase-root.Mui-disabled": {
                              "& > fieldset": {
                                borderColor: "black",
                              },
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={8} sm={9}>
                      <FormControl fullWidth>
                        <CustomLabel>{t("mobile")}</CustomLabel>
                        <Field
                          inputRef={phoneInputRef}
                          as={CustomTextField}
                          name="emailOrPhone"
                          variant="outlined"
                          fullWidth
                          placeholder={"5XXXXXXXXX"}
                          onChange={(event: any) => {
                            setFieldValue(
                              "emailOrPhone",
                              event.target.value.replace(/[^0-9]+/g, "")
                            );
                            if (event.target.value.length === 0) {
                              setIsMobileNumber(false);
                              setFieldValue("emailOrPhone", event.target.value);
                            }
                          }}
                          inputProps={{
                            maxLength: 9,
                            inputMode: "numeric",
                          }}
                          error={touched.emailOrPhone && !!errors.emailOrPhone}
                          helperText={
                            touched.emailOrPhone && errors.emailOrPhone
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                {!isMobileNumber && (
                  <>
                    <CustomLabel>
                      {isLoginActive ? t("email") : t("emailPhone")}
                    </CustomLabel>
                    <Field
                      as={CustomTextField}
                      variant="outlined"
                      fullWidth
                      type="text"
                      name="emailOrPhone"
                      placeholder={t("placeholderLogin")}
                      error={errors.emailOrPhone && touched.emailOrPhone}
                      helperText={<ErrorMessage name="emailOrPhone" />}
                      onChange={(event: any) => {
                        const isNumeric = /^[0-9]+$/.test(event.target.value);
                        if (isNumeric) {
                          setIsMobileNumber(true);
                          setFieldValue("phone", event.target.value);
                          window.setTimeout(function () {
                            if (phoneInputRef.current) {
                              phoneInputRef.current.focus();
                            }
                          }, 100);
                        } else {
                          setIsMobileNumber(false);
                          setFieldValue("emailOrPhone", event.target.value);
                        }
                        setFieldValue("emailOrPhone", event.target.value);
                      }}
                      onBlur={() => {
                        if (
                          useAuthStore.getState().email === values.emailOrPhone
                        ) {
                          setFieldValue(
                            "password",
                            useAuthStore.getState().password
                          );
                        }
                        handleEmailPhoneBlur(values, setFieldValue);
                      }}
                      onFocus={() => {
                        setFieldValue("isEmail", false);
                        setFieldValue("isPhone", false);
                        setFieldValue("password", "");
                      }}
                    />
                  </>
                )}
              </FormControl>
              {values.isEmail && (
                <>
                  <FormControl fullWidth>
                    <CustomLabel>{t("password")}</CustomLabel>
                    <Field
                      as={CustomTextField}
                      variant="outlined"
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={togglePasswordVisibility}
                              edge="end"
                              aria-label="toggle password visibility"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      name="password"
                      placeholder={t("passwordPlaceholder")}
                      error={errors.password && touched.password}
                      helperText={<ErrorMessage name="password" />}
                    />
                  </FormControl>

                  <FormOptions dir={direction}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="remember"
                          color="default"
                          checked={rememberMe}
                          onChange={(e) => setRememberMe(e.target.checked)}
                        />
                      }
                      label={t("rememberMe")}
                      sx={{
                        marginRight: "0px",
                      }}
                    />
                    <Link
                      component={RouterLink}
                      to={CurrentLanguagePath("forgotpassword")}
                      variant="body2"
                    >
                      {t("forgotPassword")}
                    </Link>
                  </FormOptions>
                </>
              )}
              <CustomButton
                onClick={() => {
                  handleSubmit();
                }}
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  textTransform: "capitalize",
                  borderRadius: "0px",
                }}
              >
                {values.isEmail ? t("login") : t("next")}
              </CustomButton>
              <Typography variant="body2" sx={{ textAlign: "center", mb: 4 }}>
                {t("dontAcc")}{" "}
                <Link
                  component={RouterLink}
                  to={CurrentLanguagePath("signup")}
                  color={"#000000"}
                  fontWeight={"700"}
                >
                  {t("register")}
                </Link>
              </Typography>
            </FormContainer>
          )}
        </Formik>
      </FormContainerWrapper>
    </Grid>
  );
}

export default Login;
