import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Card,
  CardMedia,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Button,
  Box,
  TextField,
  LinearProgress,
  InputAdornment,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import MySlider from "../../components/Slider";
import CustomButton from "../../components/Button";
import imagepath from "../../constant/imagepath";
import COLORS from "../../constant/colors";
import { Link, useNavigate } from "react-router-dom";
import { StyledTypography } from "../../utils/theme/styles/Common";
import { ReactComponent as Bin } from "../../assets/svgIcons/recycling-bin.svg";
import { useTranslation } from "react-i18next";
import { cartService } from "../../api/services/cartService";
import ShowToast from "../../utils/ShowToast";
import { useCartStore } from "../../zustand/store/cartData";
import useAuthStore from "../../zustand/store/user";
import { wishListService } from "../../api/services/wishListService";
import { userService } from "../../api/services/userService";
import LoginDialog from "../../components/LogInDialog";
import { Close } from "@mui/icons-material";
import { CurrentLanguagePath } from "../../utils/helper";
import { useLanguageStore } from "../../zustand/store/language";
import { AddToWishlist, RemoveFromCart, ViewCart } from "../../tagManager/gtag";

const StyledCard = styled(Card)({
  display: "flex",
  position: "relative",
  flexDirection: "column",
  "@media (max-width: 360px) and (max-height: 740px)": {
    width: "100%",
  },
});

const PriceTypography = styled(Typography)(({ dir }) => ({
  fontWeight: "500",
  display: "block",
  alignSelf: "end",
}));

const QuantityBox = styled(Box)({
  display: "flex",
  alignItems: "center",
});

const StyledSelect = styled(Select)({
  width: "157px",
  height: "26px",
});

const CouponBox = styled(Box)({
  padding: "13px",
  backgroundColor: COLORS.WHITE,
  justifyContent: "flex-start",
});

const CouponInputBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const FreeShippingBox = styled(Box)({
  padding: "16px",
  marginTop: "16px",
  backgroundColor: COLORS.WHITE,
  width: "100%",
});

const PaymentMethodsBox = styled(Box)({
  marginTop: "16px",
});

const PaymentIconsBox = styled(Box)({
  display: "flex",
  alignItems: "center",
});

const SummaryBox = styled(Box)({
  padding: "16px",
  marginTop: "16px",
  backgroundColor: COLORS.WHITE,
});

const RecentlyViewedBox = styled(Box)({
  backgroundColor: COLORS.WHITE,
  width: "100%",
  marginTop: "20px",
});

const Cart = () => {
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const { cartData, getCartItems, setCartData } = useCartStore();
  const navigate = useNavigate();
  const [size, setSize] = React.useState("56");
  const [coupon, setCoupon] = React.useState("");
  // const [progress, setProgress] = React.useState(50);
  const [quantities, setQuantities] = useState<number[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  // const [selectedValue, setSelectedValue] = useState("");
  const [open, setOpen] = useState<number | null>(null);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [appliedCoupon, setAppliedCoupon] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { language } = useLanguageStore();
  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClearCoupon = async () => {
    setCoupon("");
    setIsCouponApplied(false);
    setAppliedCoupon("");
    await getCartItems("");
  };

  ViewCart({
    currency: "SAR",
    value: cartData.grandtotal,
    items: cartData.cart_items.map((item) => ({
      productId: item.product_id,
      productName: item.product_name,
      price: item.price,
      variantId: item.product_variant_id,
      vendor: item.vendor_name,
      quantity: item.quantity,
    })),
  });

  useEffect(() => {
    if (cartData?.cart_items) {
      const initialQuantities = cartData.cart_items.map((item) =>
        parseInt(item.quantity, 10)
      );
      setQuantities(initialQuantities);
      ViewCart(cartData);
    }
  }, [cartData]);

  const handleSizeChange = (event: any) => {
    setSize(event.target.value);
  };

  const handleSizeOpen = (index: number) => setOpen(index);
  const handleSizeClose = () => setOpen(null);

  const handleCouponChange = (event: any) => {
    setCoupon(event.target.value);
  };

  const handleCheckout = () => {
    fetchCheckoutData(coupon);
  };

  useEffect(() => {
    getCartItems(coupon);
  }, [language]);

  const fetchCheckoutData = async (couponCode?: string) => {
    try {
      const params: any = {};
      if (couponCode && appliedCoupon && isCouponApplied) {
        params.coupon_code = couponCode;
        params.message = (appliedCoupon as string) || "";
      }
      navigate(CurrentLanguagePath("checkout"), { state: { ...params } });
    } catch (error) {
      console.error("Error fetching checkout data:", error);
    }
  };

  const updatCartQuantity = async (
    productId: string,
    variantId: string,
    quantity: number
  ) => {
    const params = {
      product_id: productId,
      product_variant_id: variantId,
      quantity: quantity,
      type: "update",
    };
    try {
      const response: any = await cartService.addToCart(params);
      if (response && response.status === 200) {
        ShowToast(response.data.message, "success");
        await getCartItems(coupon);
      } else {
        console.log("error message:-", response);
      }
    } catch (error: any) {
      ShowToast(error.message, "error", "bottom-left");
      console.log("Error adding to cart:", error);
    }
  };

  const incrementQuantity = async (
    productId: string,
    maxQuantity: number,
    variantId: string
  ) => {
    const index = cartData.cart_items.findIndex(
      (item) =>
        item.product_id === productId && item.product_variant_id === variantId
    );

    if (index !== -1) {
      if (quantities[index] < maxQuantity) {
        const updatedQuantities = [...quantities];
        updatedQuantities[index] += 1;
        await updatCartQuantity(productId, variantId, updatedQuantities[index]);
        setQuantities(updatedQuantities);
      } else {
        ShowToast("Maximum quantity reached!", "info");
      }
    } else {
      console.error("Product not found in cart items.");
    }
  };

  const decrementQuantity = async (productId: string, variantId: string) => {
    const index = cartData.cart_items.findIndex(
      (item) =>
        item.product_id === productId && item.product_variant_id === variantId
    );

    if (index !== -1) {
      const updatedQuantities = [...quantities];
      updatedQuantities[index] -= 1;
      await updatCartQuantity(productId, variantId, updatedQuantities[index]);
      setQuantities(updatedQuantities);
    } else {
      console.error("Product not found in cart items.");
    }
  };
  const removeCartItems = async (
    productId: string,
    variantId: string,
    quantity: string,
    product: any
  ) => {
    const request = {
      product_id: productId,
      product_variant_id: variantId,
      quantity: quantity,
    };
    try {
      const response = await cartService.removeCartItem(request);
      if (response && response.status === 200) {
        ShowToast("Your Cart Item Remove Successfully", "success");
        getCartItems(coupon);
        RemoveFromCart({
          currency: "SAR",
          value: product.total_price,
          items: {
            productId: product.product_id,
            productName: product.product_title,
            price: product.price,
            variantId: product.product_variant_id,
            vendor: product.vendor_name,
            quantity: product.quantity,
          },
        });
      } else {
        console.log("error message:-", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const applyCoupon = async () => {
    const request = {
      coupon_code: coupon,
    };
    try {
      const response: any = await cartService.applyCoupon(request);
      if (response && response.status === 200) {
        ShowToast(response.message, "success");
        setAppliedCoupon(response.data.discount_message);
        setIsCouponApplied(true);
        getCartItems(coupon);
      } else {
        console.log("Error message:", response);
      }
    } catch (error: any) {
      ShowToast(error.message, "error");
      console.log("Catch Error:", error);
    }
  };

  const addToWishList = async (
    productId: number,
    variantId: number,
    product: any
  ) => {
    if (useAuthStore.getState().isLoggedIn === false) {
      handleClickOpen();
      return;
    } else {
      const request = {
        product_id: productId,
        product_variant_id: variantId,
      };
      setIsLoading(true);
      try {
        const response: any = await wishListService.addToWishList(request, {
          showLoader: false,
        });
        if (response && response.status === 200) {
          ShowToast(response.message, "success");
          AddToWishlist({
            currency: "SAR",
            value: product.price,
            items: {
              productId: product.product_id,
              productName: product.product_title,
              price: product.price,
              variantId: product.product_variant_id,
              vendor: product.vendor_name,
            },
          });

          const { wishlist_id } = response.data;
          let data = cartData.recently_viewed_products;

          let newData =
            data &&
            data.map((product) => {
              if (
                product.product_id === productId &&
                product.product_variant_id === variantId
              ) {
                return {
                  ...product,
                  isAddedWishlist: true,
                  wishlist_id,
                };
              }
              return product;
            });

          // let updateKey = {};
          // updateKey = {
          //   recently_viewed_products: newData,
          // };

          setCartData({
            ...cartData,
            recently_viewed_products: newData,
          });
        } else {
          console.log("error message:-", response);
        }
      } catch (error: any) {
        setIsLoading(false);
        ShowToast(error.message, "error", "bottom-left");
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const removeToWishList = async (
    wishListId: string,
    productId: number,
    variantId: number
  ) => {
    const request = {
      wishlist_id: wishListId,
    };
    setIsLoading(true);
    try {
      const response: any = await wishListService.removeWishList(request, {
        showLoader: false,
      });

      if (response && response.status === 200) {
        ShowToast(response.message, "success");
        let data = cartData.recently_viewed_products;

        let newData =
          data &&
          data.map((product) => {
            if (
              product.product_id === productId &&
              product.product_variant_id === variantId
            ) {
              return {
                ...product,
                isAddedWishlist: false,
                wishlist_id: "",
              };
            }
            return product;
          });

        // let updateKey = {};
        // updateKey = {
        //   recently_viewed_products: newData,
        // };
        setCartData({
          ...cartData,
          recently_viewed_products: newData,
        });
      } else {
        console.log("error message:-", response);
      }
    } catch (error: any) {
      setIsLoading(false);
      ShowToast(error.message, "error", "bottom-left");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginEmail = async (values: any) => {
    const request = {
      email: `${values.email}`.toLocaleLowerCase(),
      password: values.password,
    };

    try {
      const response: any = await userService.signIn(request);
      const { data } = response;
      if (response && response.status === 200) {
        setOpenDialog(false);
        ShowToast(response.message, "success");
        useAuthStore.getState().setUserInfo(data);
      } else {
        console.log("error message:- ", response);
      }
    } catch (error: any) {
      ShowToast(error.message, "error", "bottom-left");
      console.log(error.message);
    }
  };

  const handleSizUpdate = async (old_slug: string, new_slug: string) => {
    handleSizeClose();
    const request = {
      type: "cart",
      old_slug: old_slug,
      new_slug: new_slug,
    };

    try {
      const response: any = await cartService.updateCartSize(request);
      if (response && response.status === 200) {
        await getCartItems(coupon);
      } else {
        console.log("error message:- ", response);
      }
    } catch (error: any) {
      ShowToast(error.message, "error", "bottom-left");
      console.log(error.message);
    }
  };
  const getShippingProcess = (): number => {
    if (cartData?.free_shipping_threshold) {
      const subtotal = parseFloat(cartData.subtotal as string);
      const threshold = parseFloat(cartData.free_shipping_threshold as string);

      if (isNaN(subtotal) || isNaN(threshold) || threshold <= 0) {
        return 0;
      }

      const progress = (subtotal / threshold) * 100;
      return Math.min(progress, 100);
    }

    return 0;
  };

  return (
    <>
      {cartData && cartData.cart_items?.length > 0 && (
        <Box
          sx={{
            display: { xs: "block", sm: "none" },
            width: "100%",
            backgroundColor: "#fff",
            padding: "16px",
            paddingBottom: "5px",
            position: "fixed",
            zIndex: 2,
            pb: "0px",
          }}
        >
          {cartData?.freeShippingMessage && (
            <div
              dangerouslySetInnerHTML={{
                __html: cartData.freeShippingMessage,
              }}
              style={{
                fontSize: "14px",
                fontWeight: "300",
                textAlign: "center",
                justifyContent: "center",
                display: "flex",
                marginBottom: "4px",
                alignItems: "center",
              }}
            />
          )}
          <Box sx={{ width: "100%" }}>
            <LinearProgress
              className="rtl-transform"
              variant="determinate"
              value={getShippingProcess()}
              sx={{
                height: 6,
                backgroundColor: "#FFFFFF",
                border: "1px solid black",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: COLORS.COPPER_ORANGE,
                },
              }}
            />
          </Box>
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
              mt: 2,
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: "500",
                  lineHeight: "17.68px",
                  textAlign: "left",
                }}
              >
                {t("cart.grand_total")}:
              </Typography>
              <Typography
                component={"span"}
                sx={{
                  fontSize: "15px",
                  fontWeight: "400",
                  lineHeight: "17.68px",
                  textAlign: "left",
                }}
              >
                {cartData?.grandtotal} {t("common.sar")}
              </Typography>
            </Box>
            <CustomButton
              sx={{
                mt: 2,
                mb: 2,
                padding: "10px 8px ",
                borderRadius: "0px",
                width: "142px",
                textTransform: "capitalize",

                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "23.12px",
                textAlign: "left",
              }}
              onClick={handleCheckout}
            >
              {t("common.checkout")}
            </CustomButton>
          </Box>
        </Box>
      )}

      <Grid
        bgcolor={COLORS.WHITE_SMOKE}
        sx={{
          overflowX: "hidden",
          marginTop: {
            xs: cartData?.cart_items?.length === 0 ? "0px" : "130px",
            sm: "0px",
          },
        }}
      >
        {cartData?.cart_items?.length > 0 ? (
          <Container maxWidth={"lg"}>
            <StyledTypography
              sx={{
                fontSize: { xs: "15px !important", sm: "16px !important" },
                pt: "5px !important",
                pb: "0px !important",
              }}
              fontWeight={"500"}
              mt={"12px"}
              mb={1}
            >
              {t("cart.your_bag")}:&nbsp;
              <Box
                component="span"
                sx={{ fontSize: { xs: "15px", sm: "16px" } }}
                fontWeight={"300"}
              >
                {cartData?.cart_items?.length}&nbsp;{t("cart.item")}
              </Box>
            </StyledTypography>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={8}
                sx={{ alignItems: "center", width: "100%" }}
              >
                {cartData?.cart_items?.map((product, index) => (
                  <StyledCard
                    elevation={0}
                    key={product.product_id}
                    sx={{
                      mb: "12px",
                      borderRadius: "0px",
                      // alignItems: "center",
                      p: "10px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Link
                        to={CurrentLanguagePath(
                          `product-details/${product.product_slug}`
                        )}
                      >
                        <CardMedia
                          component="img"
                          sx={{
                            width: 107,
                            minHeight: "159px",
                            cursor: "pointer",
                          }}
                          image={product.product_image_url}
                          alt="Product Image"
                        />
                      </Link>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          flex: "1",
                          [direction === "rtl" ? "marginRight" : "marginLeft"]:
                            "15px",
                        }}
                      >
                        <Box
                          sx={{
                            display: {
                              xs: "none",
                              sm: "flex",
                              alignSelf: "flex-end",
                            },
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "20.23px",
                              // textAlign: "left",
                              mt: 1,
                              textDecoration:
                                product.sale_price && "line-through",
                            }}
                          >
                            {product.price} {t("common.sar")}
                          </Typography>
                          {product.sale_price && (
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                lineHeight: "20.23px",
                                // textAlign: "left",
                                mt: 1,
                                color: "#E51C1C",
                              }}
                            >
                              &nbsp; {product.sale_price} {t("common.sar")}
                            </Typography>
                          )}
                        </Box>
                        {product && product.discount_label && (
                          <PriceTypography
                            fontSize={"14px"}
                            sx={{ display: { xs: "none", sm: "flex" } }}
                            dir={direction}
                          >
                            {product.discount_label}
                          </PriceTypography>
                        )}

                        <Box sx={{}}>
                          <Typography
                            component="p"
                            fontSize={"20px"}
                            lineHeight={"20px"}
                            fontWeight={"400"}
                          >
                            {product.vendor_name}
                          </Typography>
                          <Typography
                            color={COLORS.BLACK}
                            component="p"
                            fontSize={"16px"}
                            fontWeight={"300"}
                            lineHeight={"23px"}
                            sx={{
                              flex: 1,
                              "@media (max-width: 480px)": {
                                fontSize: "14px",
                              },
                            }}
                          >
                            {product.product_name}
                          </Typography>
                          <Typography
                            fontWeight={"500"}
                            fontSize={"12px"}
                            sx={{ mt: "2px" }}
                          >
                            {t("common.color")}:{product.color_name}
                          </Typography>
                          {product.sizes && product.sizes.length > 0 && (
                            <StyledSelect
                              disabled={product.sizes.length === 1}
                              open={open === index}
                              onClose={handleSizeClose}
                              onOpen={() => handleSizeOpen(index)}
                              value={product.product_slug}
                              onChange={handleSizeChange}
                              variant="outlined"
                              size="small"
                              id="my-simple-select"
                              sx={{
                                mt: "5px",
                                height: "26px",
                                "& #my-simple-select": {
                                  fontSize: "12px",
                                  fontWeight: "400",
                                },
                                "& .MuiSelect-icon": {
                                  left: direction === "rtl" ? "7px" : "unset",
                                  right: direction === "rtl" ? "unset" : "7px",
                                },
                                "& .MuiOutlinedInput-input": {
                                  paddingRight: "12px !important",
                                },
                              }}
                            >
                              {product.sizes?.map((item) => (
                                <MenuItem
                                  disabled={
                                    product.product_slug === item.product_slug
                                  }
                                  key={item.size}
                                  value={item.product_slug}
                                  onClick={() =>
                                    handleSizUpdate(
                                      product.product_slug,
                                      item.product_slug
                                    )
                                  }
                                >
                                  {item.size}
                                </MenuItem>
                              ))}
                            </StyledSelect>
                          )}

                          <QuantityBox sx={{ mt: "5px" }}>
                            <Typography fontSize={"14px"} fontWeight={"400"}>
                              {t("cart.quantity")}:
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "1px solid #A4A1AA33",
                                m: "4px",
                                cursor: "pointer",
                                padding: 0,
                              }}
                              component={"div"}
                            >
                              <Box
                                component={"button"}
                                disabled={quantities[index] === 1}
                                sx={{
                                  height: "18px",
                                  width: "16px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRight: "1px solid #A4A1AA33",
                                  backgroundColor: "#A4A1AA33",
                                  opacity: quantities[index] === 1 ? 0.5 : 1,
                                  cursor:
                                    quantities[index] === 1
                                      ? "unset"
                                      : "pointer",
                                  border: "none",
                                }}
                                onClick={() => {
                                  decrementQuantity(
                                    product.product_id,
                                    product.product_variant_id
                                  );
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: "12px", fontWeight: "400" }}
                                >
                                  -
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  height: "18px",
                                  width: "22px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRight: "1px solid #A4A1AA33",
                                  background: "#fff",
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: "12px", fontWeight: "400" }}
                                >
                                  {quantities[index]}
                                </Typography>
                              </Box>

                              <Box
                                component={"button"}
                                sx={{
                                  height: "18px",
                                  width: "16px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderLeft: "1px solid #A4A1AA33",
                                  backgroundColor: "#A4A1AA33",
                                  border: "none",
                                }}
                                onClick={() =>
                                  incrementQuantity(
                                    product.product_id,
                                    parseInt(product.total_stock),
                                    product.product_variant_id
                                  )
                                }
                              >
                                <Typography
                                  sx={{ fontSize: "12px", fontWeight: "400" }}
                                >
                                  +
                                </Typography>
                              </Box>
                            </Box>
                          </QuantityBox>
                          <Box sx={{ display: { xs: "flex", sm: "none" } }}>
                            <Typography
                              sx={{
                                display: { xs: "flex", sm: "none" },
                                "@media (max-width: 400px)": {
                                  fontSize: "11px",
                                },
                                fontSize: "14px",
                                fontWeight: "500",
                                lineHeight: "20.23px",
                                textAlign: "left",
                                textDecoration:
                                  product.sale_price && "line-through",
                              }}
                            >
                              {product.price} {t("common.sar")}
                            </Typography>
                            {product.sale_price && (
                              <Typography
                                sx={{
                                  "@media (max-width: 400px)": {
                                    fontSize: "11px",
                                  },
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  lineHeight: "20.23px",
                                  color: "#E51C1C",
                                }}
                              >
                                &nbsp; {product.sale_price} {t("common.sar")}
                              </Typography>
                            )}
                          </Box>
                          {product && product.discount_label && (
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                lineHeight: "20.23px",
                                display: { xs: "flex", sm: "none" },
                              }}
                            >
                              {product.discount_label}
                            </Typography>
                          )}
                          <Box
                            sx={{
                              display: { xs: "none", sm: "flex" },
                              justifyContent: "flex-start",
                            }}
                          >
                            <Button
                              onClick={() =>
                                removeCartItems(
                                  product.product_id,
                                  product.product_variant_id,
                                  "0",
                                  product
                                )
                              }
                              startIcon={
                                <Bin
                                  style={{
                                    width: "12px",
                                    height: "20px",
                                  }}
                                />
                              }
                              disableRipple
                              disableFocusRipple
                              sx={{
                                textTransform: "capitalize",
                                fontWeight: "400",
                                fontSize: "9px",
                                display: "flex",
                                minWidth: "unset  !important",
                                padding: "0px !important",
                                "& .MuiButton-startIcon": {
                                  mx: "2px !important",
                                },
                              }}
                            >
                              Remove
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </div>
                    <Box
                      sx={{
                        display: { xs: "flex", sm: "none" },
                        justifyContent: "flex-end",
                        width: "100%",
                      }}
                    >
                      <Button
                        onClick={() => {
                          removeCartItems(
                            product.product_id,
                            product.product_variant_id,
                            "0",
                            product
                          );
                        }}
                        startIcon={
                          <Bin
                            style={{
                              width: "12px",
                              height: "20px",
                            }}
                          />
                        }
                        disableRipple
                        disableFocusRipple
                        sx={{
                          textTransform: "capitalize",
                          fontWeight: "400",
                          fontSize: "9px",
                          display: "flex",
                          minWidth: "unset  !important",
                          padding: "0px !important",
                          "& .MuiButton-startIcon": {
                            mx: "2px !important",
                          },
                        }}
                      >
                        Remove
                      </Button>
                    </Box>
                  </StyledCard>
                ))}
                <Box
                  sx={{
                    display: { xs: "none", sm: "flex" },
                    justifyContent: "center",
                    mt: 2,
                  }}
                >
                  <CustomButton
                    onClick={() => navigate(CurrentLanguagePath(""))}
                    variant="contained"
                    sx={{
                      textTransform: "capitalize",
                      width: {
                        xs: "100%",
                        md: "50%",
                      },
                      height: "46px",
                      padding: "11px 15px",
                      borderRadius: "0px",
                    }}
                  >
                    {t("cart.continue_shopping")}
                  </CustomButton>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <CouponBox>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "500",
                      lineHeight: "21.68px",
                      textAlign: direction === "rtl" ? "right" : "left",
                    }}
                  >
                    {t("cart.add_coupon")}
                  </Typography>
                  {/* <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "17.34px",
                      textAlign: direction === "rtl" ? "right" : "left",
                      color: COLORS.COPPER_ORANGE,
                      marginTop: "8px",
                      mb: "3px",
                    }}
                  >
                    {t("cart.use_code")}&nbsp;
                    <span style={{ fontWeight: "500" }}>MAY</span>{" "}
                    {t("cart.get_the_offer")}
                  </Typography> */}
                  <CouponInputBox>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={coupon}
                      onChange={handleCouponChange}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          color: "#000",
                          mt: "3px",
                          mb: 2,
                          height: "28px",
                          borderRadius: "2px",

                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "17.34px",
                          overflow: "hidden",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#000",
                            borderWidth: ".5px",
                          },
                        },
                        "&.Mui-focused": {
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "0px solid #000",
                            borderRadius: "0px",
                          },
                        },
                      }}
                      inputProps={{
                        readOnly: Boolean(isCouponApplied),
                        disabled: Boolean(isCouponApplied),
                      }}
                      placeholder="Enter promo code"
                      InputProps={{
                        endAdornment: isCouponApplied && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClearCoupon}
                              edge="end"
                              sx={{ padding: "0px" }}
                            >
                              <Close
                                sx={{
                                  fontSize: "16px",
                                  color: "#fff",
                                  backgroundColor: "black",
                                  padding: "2px",
                                  borderRadius: "8px",
                                  mx: "5px",
                                }}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Button
                      variant="outlined"
                      onClick={() => {
                        if (coupon && !isCouponApplied) {
                          applyCoupon();
                        }
                      }}
                      disabled={isCouponApplied}
                      disableRipple={!isCouponApplied}
                      sx={{
                        ml: 1,
                        mt: "3px",
                        mb: 2,
                        width: "79px",
                        border: "1px solid #131118",
                        padding: "1px",
                        height: "28px",
                        borderRadius: "2px",

                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "20.23px",
                        textAlign: "center",
                        marginRight: direction === "rtl" ? 1 : "unset",
                        "&.Mui-disabled": {
                          color: "#000",
                          opacity: 0.7,
                        },
                      }}
                    >
                      {!isCouponApplied ? t("cart.apply") : t("cart.applied")}
                    </Button>
                  </CouponInputBox>
                  {appliedCoupon && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        lineHeight: "17.34px",
                        textAlign: direction === "rtl" ? "right" : "left",
                        color: "green",
                        // mb: "3px",
                      }}
                    >
                      {appliedCoupon}
                    </Typography>
                  )}
                </CouponBox>

                <Box
                  sx={{ display: { xs: "none", sm: "block" }, width: "100%" }}
                >
                  <FreeShippingBox>
                    {cartData?.freeShippingMessage && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: cartData.freeShippingMessage,
                        }}
                        style={{
                          fontSize: "14px",
                          // fontWeight: "300",
                          textAlign: "center",
                          justifyContent: "center",
                          display: "flex",
                          marginBottom: "4px",
                          alignItems: "center",
                        }}
                      />
                    )}
                    <Box sx={{ width: "100%", mt: 1 }}>
                      <LinearProgress
                        className="rtl-transform"
                        variant="determinate"
                        value={getShippingProcess()}
                        sx={{
                          height: 6,
                          backgroundColor: "#FFFFFF",
                          border: "1px solid black",
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: COLORS.COPPER_ORANGE,
                          },
                        }}
                      />
                    </Box>
                  </FreeShippingBox>
                </Box>
                <PaymentMethodsBox>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "500",
                      lineHeight: "21.68px",
                      textAlign: direction === "rtl" ? "right" : "left",
                      textTransform: "uppercase",
                      mb: "2px",
                      [direction === "rtl" ? "marginRight" : "marginLeft"]:
                        "4px",
                    }}
                  >
                    {t("cart.payment_methods")}
                  </Typography>
                  <PaymentIconsBox>
                    <img
                      src={imagepath.mada}
                      alt="Mada"
                      width={57}
                      style={{
                        marginRight: direction === "rtl" ? "unset" : "6px",
                      }}
                    />
                    <img
                      src={imagepath.visa}
                      alt="Visa"
                      width={58}
                      style={{ marginRight: "6px" }}
                    />
                    <img
                      src={imagepath.masterCard}
                      alt="Master-card"
                      width={38}
                      style={{ marginRight: "6px" }}
                    />
                    <img
                      src={imagepath.applePay}
                      alt="Apple Pay"
                      width={54}
                      height={"100%"}
                      style={{
                        marginRight: direction === "rtl" ? "10px" : "unset",
                        marginLeft: direction === "rtl" ? "unset" : "6px",
                      }}
                    />
                  </PaymentIconsBox>
                </PaymentMethodsBox>
                <SummaryBox>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: "4px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "300",
                        lineHeight: "23.12px",
                        textAlign: "left",
                      }}
                    >
                      {t("cart.sub_total")}:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "300",
                        lineHeight: "23.12px",
                        textAlign: "left",
                      }}
                    >
                      {cartData.subtotal} {t("common.sar")}
                    </Typography>
                  </Box>

                  {cartData.discountedAmount &&
                    Number(cartData.discountedAmount) > 0 && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            mb: "4px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "20px",
                              textAlign: "left",
                              color: "#E51C1C",
                            }}
                          >
                            {t("cart.discount")}:
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "20px",
                              textAlign: "left",
                              color: "#E51C1C",
                            }}
                          >
                            -{cartData.discountedAmount} {t("common.sar")}
                          </Typography>
                        </Box>
                        <Divider sx={{ borderColor: "#E51C1C", mb: "10px" }} />
                      </>
                    )}

                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        lineHeight: "13.34px",
                        textAlign: "left",
                      }}
                    >
                      {t("cart.grand_total")}:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "13.34px",
                        textAlign: "left",
                      }}
                    >
                      {cartData.grandtotal} {t("common.sar")}
                    </Typography>
                  </Box>
                </SummaryBox>
                <CustomButton
                  fullWidth
                  sx={{
                    mt: "20px",
                    mb: "10px",
                    padding: "10px 8px ",
                    borderRadius: "0px",

                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "23.12px",
                    textAlign: "left",
                  }}
                  onClick={handleCheckout}
                >
                  {t("common.checkout")}
                </CustomButton>
                <CustomButton
                  onClick={() => navigate(CurrentLanguagePath(""))}
                  variant="contained"
                  sx={{
                    display: { xs: "flex", sm: "none" },
                    textTransform: "capitalize",
                    width: {
                      xs: "100%",
                      md: "50%",
                    },
                    height: "46px",
                    padding: "11px 15px",
                    borderRadius: "0px",
                  }}
                >
                  {t("cart.continue_shopping")}
                </CustomButton>
              </Grid>
            </Grid>
          </Container>
        ) : (
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginBottom: "10px",
              padding: "35px 0 0",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
            >
              Your Bag is empty
            </Typography>
            <CustomButton
              fullWidth={false}
              variant="contained"
              sx={{
                textTransform: "capitalize",
                height: "46px",
                padding: "11px 15px",
                borderRadius: "0px",
              }}
              onClick={() => navigate(CurrentLanguagePath(""))}
            >
              {t("cart.continue_shopping")}
            </CustomButton>
          </Box>
        )}
        {cartData && cartData.isRecently && (
          <RecentlyViewedBox>
            <MySlider
              products={cartData.recently_viewed_products || []}
              marginTop={3}
              title={"RECENTLY VIEWED"}
              handleWishlist={(index) => {
                if (!cartData?.recently_viewed_products) {
                  return;
                }
                const {
                  product_id,
                  product_variant_id,
                  wishlist_id,
                  isAddedWishlist,
                } = cartData.recently_viewed_products[index];
                if (!isLoading) {
                  if (isAddedWishlist) {
                    removeToWishList(
                      wishlist_id,
                      product_id,
                      product_variant_id
                    );
                  } else {
                    addToWishList(
                      product_id,
                      product_variant_id,
                      cartData.recently_viewed_products[index]
                    );
                  }
                }
              }}
            />
          </RecentlyViewedBox>
        )}

        <LoginDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          handleLogin={handleLoginEmail}
        />
      </Grid>
    </>
  );
};

export default Cart;
