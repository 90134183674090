import {
  Container,
  TextField,
  Grid,
  Box,
  Button,
  Switch,
  FormControlLabel,
  Typography,
  InputAdornment,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import COLORS from "../../constant/colors";
import { ArrowForward, ChevronLeft } from "@mui/icons-material";
import LeftMenuBar from "../../components/LeftMenuBar";
import { useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useTranslation } from "react-i18next";
import { userService } from "../../api/services/userService";
import { Formik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import ShowToast from "../../utils/ShowToast";
import UpdateEmailDialog from "../../components/EmailDialog";
import UpdatePasswordDialog from "../../components/PasswordDialog";
import useAuthStore from "../../zustand/store/user";
import { CurrentLanguagePath } from "../../utils/helper";

function UserProfile() {
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const navigate = useNavigate();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    country_code: "+966",
    phone: "",
    dob: "",
    is_newsletter_subscribe: false,
    email: "",
  });
  const validationSchema = Yup.object({
    first_name: Yup.string()
      .required("First Name is required")
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .max(50, "First Name must be at most 50 characters"),
    last_name: Yup.string()
      .required("Last Name is required")
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .max(50, "Last Name must be at most 50 characters"),
    phone: Yup.string()
      .required("Mobile number is required")
      .matches(/^5\d{8}$/, "Invalid mobile number"),
    dob: Yup.date()
      .nullable()
      .typeError("Invalid Date")
      .required("Date of Birth is required"),
  });

  useEffect(() => {
    getProfile();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getProfile = async () => {
    try {
      const response = await userService.getUserProfile();
      if (response && response.status === 200) {
        const {
          first_name,
          last_name,
          country_code,
          phone,
          dob,
          email,
          is_newsletter_subscribe,
        }: any = response.data;
        setInitialValues((pri: any) => ({
          ...pri,
          first_name,
          last_name,
          country_code,
          is_newsletter_subscribe,
          phone,
          dob: dob ? dayjs(dob).format("YYYY-MM-DD") : "",
          email,
        }));
      } else {
        console.log("error message:-", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateProfile = async (values: any) => {
    const dataParam = values;
    dataParam.is_newsletter_subscribe = values.is_newsletter_subscribe ? 1 : 0;
    try {
      const response: any = await userService.updateUserProfile(dataParam);
      const { data } = response;

      if (response && response.status === 200) {
        ShowToast(response.message, "success");
        useAuthStore.getState().setUserInfo(data);
        getProfile();
      } else {
        console.log("error message:-", response.data);
      }
    } catch (error: any) {
      ShowToast(error?.message, "error", "bottom-left");
      console.log(error?.message);
    }
  };
  const updatePassword = async (values: any) => {
    try {
      const request = {
        current_password: values.current_password,
        password: values.password,
        confirm_password: values.confirm_password,
      };
      const response: any = await userService.changePassword(request);
      if (response && response.status === 200) {
        ShowToast(response.message, "success");
        setOpen(false);
      } else {
        console.log("error message:-", response.data);
      }
    } catch (error: any) {
      ShowToast(error?.message, "error", "bottom-left");
      console.log(error?.message);
    }
  };

  const updateEmail = async (values: any) => {
    try {
      const request = {
        current_email: values.current_email,
        current_password: values.current_password,
        new_email: `${values.new_email}`.toLocaleLowerCase(),
      };
      const response: any = await userService.changeEmail(request);
      const { data } = response;
      if (response && response.status === 200) {
        useAuthStore.getState().setUserInfo(data);
        ShowToast(response.message, "success");
        getProfile();
        setIsOpen(false);
      } else {
        console.log("error message:-", response.data);
      }
    } catch (error: any) {
      ShowToast(error?.message, "error", "bottom-left");
      console.log(error?.message);
    }
  };

  return (
    <Box
      sx={{
        background: "rgb(164 161 170 / 20%)",
        minHeight: "62vh",
      }}
    >
      <Container maxWidth={"xl"}>
        <Grid
          container
          pb={8}
          sx={{
            paddingLeft: { xs: "0%", sm: "0%" },
            paddingRight: { xs: "0%", sm: "0%" },
            marginLeft: { xs: "0%", sm: "0%" },
            marginRight: { xs: "0%", sm: "0%" },
            paddingTop: { xs: "0%", sm: "2%" },
          }}
          columns={12}
        >
          <LeftMenuBar selectedItem={3} />

          <Grid item xs={12} lg={8} md={8} gap={0}>
            {isSmall && (
              <Box mb={2} display={"flex"} alignItems={"center"}>
                <IconButton
                  onClick={() => navigate(CurrentLanguagePath("account"))}
                  size="small"
                  sx={{
                    [direction === "rtl" ? "marginRight" : "marginLeft"]:
                      "-10px",
                    padding: "0px !important",
                    fontSize: "20px",
                    width: "25px",
                  }}
                >
                  <ChevronLeft className="rtl-transform" />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "26.01px",
                  }}
                >
                  {t("common.overview")}
                </Typography>
              </Box>
            )}
            <Box>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "26.01px",
                  mb: 2,
                }}
              >
                {t("userInfo.contact_details")}
              </Typography>
              <Box>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values: any) => updateProfile(values)}
                  validateOnChange
                  enableReinitialize
                  validateOnMount
                  isInitialValid={false}
                >
                  {({
                    errors,
                    touched,
                    setFieldValue,
                    values,
                    isValid,
                    handleChange,
                    handleSubmit,
                  }) => {
                    return (
                      <div>
                        <Grid
                          container
                          spacing={{ xs: 0, sm: 3 }}
                          mb={{ xs: "10px", sm: "unset" }}
                        >
                          <Grid item xs={12} sm={6}>
                            <TextField
                              onChange={handleChange}
                              name="first_name"
                              fullWidth
                              label="First Name"
                              value={values.first_name}
                              error={touched.first_name && !!errors.first_name}
                              helperText={
                                touched.first_name && errors.first_name
                              }
                              variant="outlined"
                              inputProps={{
                                maxLength: 50,
                              }}
                              sx={{
                                borderRadius: "4px",
                                "& label": {
                                  transformOrigin:
                                    direction === "rtl"
                                      ? "right !important"
                                      : "unset",
                                  left:
                                    direction === "rtl"
                                      ? "inherit !important"
                                      : "unset",
                                  right:
                                    direction === "rtl"
                                      ? "1.75rem !important"
                                      : "unset",
                                  fontSize: "small",
                                  color: "#000000",
                                  fontWeight: 400,
                                  overflow: "unset",
                                },
                                "& .MuiInputLabel-root": {
                                  fontSize: "small",
                                  color: "#000000",
                                  fontWeight: 400,
                                },
                                "& .MuiOutlinedInput-root": {
                                  height: "50px",
                                  backgroundColor: COLORS.WHITE,
                                  fontWeight: 400,
                                  lineHeight: "17.34px",
                                  "& legend": {
                                    textAlign:
                                      direction === "rtl" ? "right" : "left",
                                  },
                                },
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{ mt: { xs: "10px", sm: "unset" } }}
                          >
                            <TextField
                              fullWidth
                              onChange={handleChange}
                              label="Last Name"
                              variant="outlined"
                              name="last_name"
                              value={values.last_name}
                              error={touched.last_name && !!errors.last_name}
                              helperText={touched.last_name && errors.last_name}
                              inputProps={{
                                maxLength: 50,
                              }}
                              sx={{
                                borderRadius: "4px",
                                "& label": {
                                  transformOrigin:
                                    direction === "rtl"
                                      ? "right !important"
                                      : "unset",
                                  left:
                                    direction === "rtl"
                                      ? "inherit !important"
                                      : "unset",
                                  right:
                                    direction === "rtl"
                                      ? "1.75rem !important"
                                      : "unset",
                                  fontSize: "small",
                                  color: "#000000",
                                  fontWeight: 400,
                                  overflow: "unset",
                                },
                                "& .MuiInputLabel-root": {
                                  fontSize: "small",
                                  color: "#000000",
                                  fontWeight: 400,
                                },
                                "& .MuiOutlinedInput-root": {
                                  height: "50px",
                                  backgroundColor: COLORS.WHITE,
                                  fontWeight: 400,
                                  lineHeight: "17.34px",
                                  "& legend": {
                                    textAlign:
                                      direction === "rtl" ? "right" : "left",
                                  },
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={{ xs: 0, sm: 3 }}>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{ display: "flex", width: "100%", mt: "15px" }}
                          >
                            <TextField
                              label="Country Code"
                              variant="outlined"
                              // defaultValue={values.country_code || "+966"}
                              name="country_code"
                              value={values.country_code || "+966"}
                              disabled
                              sx={{
                                borderRadius: "4px",
                                marginRight:
                                  direction === "rtl" ? "unset" : "5px",
                                "& label": {
                                  transformOrigin:
                                    direction === "rtl"
                                      ? "right !important"
                                      : "unset",
                                  left:
                                    direction === "rtl"
                                      ? "inherit !important"
                                      : "unset",
                                  right:
                                    direction === "rtl"
                                      ? "1.75rem !important"
                                      : "unset",
                                  fontSize: "small",
                                  color: "#000000",
                                  fontWeight: 400,
                                  overflow: "unset",
                                },
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                                ".css-14olnj9-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled":
                                  {
                                    color: "#000000 !important",
                                  },
                                "& .MuiInputLabel-root": {
                                  fontSize: "small",
                                  color: "#000000",
                                  fontWeight: 400,
                                },
                                "& .MuiOutlinedInput-root": {
                                  height: "50px",
                                  backgroundColor: COLORS.WHITE,
                                  fontWeight: 400,
                                  lineHeight: "17.34px",
                                  "& legend": {
                                    textAlign:
                                      direction === "rtl" ? "right" : "left",
                                  },
                                },
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Box
                                      component="img"
                                      src="https://vogacloset.com/3.0.28/static/media/sa.svg"
                                      alt="Dubai Flag"
                                      sx={{ width: "20px", height: "auto" }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              fullWidth
                              onChange={(event) => {
                                setFieldValue(
                                  "phone",
                                  event.target.value.replace(/[^0-9]+/g, "")
                                );
                              }}
                              label="Phone Number"
                              placeholder="5XXXXXXXX"
                              variant="outlined"
                              name="phone"
                              value={values.phone}
                              error={touched.phone && !!errors.phone}
                              helperText={touched.phone && errors.phone}
                              inputProps={{
                                maxLength: 9,
                                inputMode: "numeric",
                              }}
                              sx={{
                                borderRadius: "4px",
                                marginRight:
                                  direction === "rtl" ? "5px" : "unset",
                                "& label": {
                                  transformOrigin:
                                    direction === "rtl"
                                      ? "right !important"
                                      : "unset",
                                  left:
                                    direction === "rtl"
                                      ? "inherit !important"
                                      : "unset",
                                  right:
                                    direction === "rtl"
                                      ? "1.75rem !important"
                                      : "unset",
                                  fontSize: "small",
                                  color: "#000000",
                                  fontWeight: 400,
                                  overflow: "unset",
                                },
                                "& .MuiInputLabel-root": {
                                  fontSize: "small",
                                  color: "#000000",
                                  fontWeight: 400,
                                },
                                "& .MuiOutlinedInput-root": {
                                  height: "50px",
                                  backgroundColor: COLORS.WHITE,
                                  fontWeight: 400,
                                  lineHeight: "17.34px",
                                  "& legend": {
                                    textAlign:
                                      direction === "rtl" ? "right" : "left",
                                  },
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={{ xs: 0, sm: 3 }}>
                          <Grid item xs={12} sm={6} mt={"7px"}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  disableFuture
                                  value={values.dob ? dayjs(values.dob) : null}
                                  name="dob"
                                  onChange={(e) => {
                                    if (e) {
                                      setFieldValue(
                                        "dob",
                                        dayjs(e).format("YYYY-MM-DD")
                                      );
                                    }
                                  }}
                                  format="YYYY-MM-DD"
                                  label="Date of Birth"
                                  sx={{
                                    width: "100%",
                                    backgroundColor: COLORS.WHITE,
                                    borderRadius: "4px",
                                    direction: direction,
                                    "& label": {
                                      transformOrigin:
                                        direction === "rtl"
                                          ? "right !important"
                                          : "unset",
                                      left:
                                        direction === "rtl"
                                          ? "inherit !important"
                                          : "unset",
                                      right:
                                        direction === "rtl"
                                          ? "1.75rem !important"
                                          : "unset",
                                      fontSize: "small",
                                      color: "#000000",
                                      fontWeight: 400,
                                      overflow: "hidden",
                                    },

                                    "& .MuiOutlinedInput-root": {
                                      "& legend": {
                                        textAlign:
                                          direction === "rtl"
                                            ? "right"
                                            : "left",
                                      },
                                    },
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                            {touched.dob && errors.dob && (
                              <Typography color="error" variant="body2">
                                {errors.dob.toString()}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                        <Box mt={1}>
                          <Grid container spacing={{ xs: 0, sm: 3 }} mt={"1px"}>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                sx={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  lineHeight: "26.01px",
                                  marginBottom: "15px",
                                }}
                              >
                                {t("userInfo.change_email")}
                              </Typography>
                              <Box
                                component={"div"}
                                bgcolor={COLORS.WHITE}
                                sx={{
                                  width: "100%",
                                  height: "50px",
                                  borderRadius: "4px",
                                }}
                                onClick={() => setIsOpen(true)}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "12px 20px",
                                  }}
                                >
                                  <Typography textAlign={"center"}>
                                    {initialValues.email}
                                  </Typography>
                                  <ArrowForward className="rtl-transform" />
                                </Box>
                              </Box>
                              <Box mt={1}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      lineHeight: "23.12px",
                                    }}
                                  >
                                    {t("userInfo.subscribe_prompt")}
                                  </Typography>
                                  <FormControlLabel
                                    className="rtl-transform"
                                    control={
                                      <Switch
                                        value={values.is_newsletter_subscribe}
                                        checked={
                                          Boolean(
                                            values.is_newsletter_subscribe
                                          ) === true
                                        }
                                        onChange={(e) =>
                                          setFieldValue(
                                            "is_newsletter_subscribe",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    }
                                    label=""
                                    labelPlacement={
                                      direction === "rtl" ? "end" : "start"
                                    }
                                    sx={{ margin: "0px !important" }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                sx={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  lineHeight: "26.01px",
                                  marginBottom: "15px",
                                }}
                              >
                                {t("userInfo.change_password")}
                              </Typography>
                              <Box
                                component={"div"}
                                bgcolor={COLORS.WHITE}
                                sx={{
                                  width: "100%",
                                  height: "50px",
                                  borderRadius: "4px",
                                }}
                                onClick={() => handleOpen()}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "12px 20px",
                                  }}
                                >
                                  <Typography textAlign={"center"}>
                                    ***********
                                  </Typography>
                                  <ArrowForward className="rtl-transform" />
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>

                        <Grid xs={12} sm={6} mt={1} mb={1}>
                          <Button
                            onClick={() => {
                              handleSubmit();
                            }}
                            fullWidth
                            variant="contained"
                            sx={{
                              borderRadius: "0px",
                              width: { xs: "100%", sm: "96%" },
                              height: "44px",
                            }}
                          >
                            {t("userInfo.save")}
                          </Button>
                        </Grid>
                      </div>
                    );
                  }}
                </Formik>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <UpdateEmailDialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          updateEmail={updateEmail}
        />
        <UpdatePasswordDialog
          open={open}
          onClose={handleClose}
          onSubmit={updatePassword}
        />
      </Container>
    </Box>
  );
}

export default UserProfile;
